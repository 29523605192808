import React, { useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import logotm from "../assets/img/logotm.png";
import {
  FaBuilding,
  FaPassport,
  FaCamera,
  FaEnvelope,
  FaPhone,
} from "react-icons/fa";
import telephone from "../assets/img/telephone.png";


const Navbar = () => {
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  return (

    <section className="bg-white">
    <div className="">
      <div className="md:fixed fixed-none border-b bg-white w-full z-40 text-black md:flex flex-none justify-between items-center md:h-20 h-28 xl:px-28 lg:px-4 md:px-4 px-2">
        <img
          src={logotm}
          className="mb-1 md:ml-0 ml-28 xl:w-44 lg:w-32 md:w-32 w-36 xl:h-14 lg:h-10 md:h-10 h-12 md:mt-0 mt-2"
        />
        <div className="xl:block lg:block md:block hidden  bg-gray-100 border rounded-full xl:px-4 lg:px-4 px-4 md:mt-0 mt-4 md:py-2 xl:py-1 py-2 ">
          <div className="flex items-center">
          <div className="flex">
            <div className="">
              <img
                src={telephone}
                className="xl:w-4 xl:mt-1 mt-1 w-4"
              />
            </div>
            <div>
              <a href="tel:08046427999" style={{ fontFamily: "Figtree" }}>
                <p className="fontfamily text-black ml-2 font-semibold xl:text-lg lg:text-lg md:text-sm text-sm">
                  080 4642 7999
                </p>
              </a>
            </div>
          </div>

          <div className="flex">
            <div className="xl:ml-4 md:ml-4 ml-4 xl:mt-2 lg:mt-2 mt-1">
              <FaEnvelope className="text-blue-700 " size={16} />
            </div>
            <div>
              <a
                href="mailto:enquiry@trawelmart.com"
                style={{ fontFamily: "Figtree" }}
              >
                <p className="fontfamily text-black ml-2 -mt-111 font-semibold xl:text-lg lg:text-lg md:text-sm text-sm">
                  enquiry@trawelmart.com
                </p>
              </a>
            </div>
          </div>
        </div>
        </div>



        <div className="xl:hidden text-center justify-center lg:hidden md:hidden block xl:px-4 lg:px-4 px-4 md:mt-0 mt-1 md:py-2 xl:py-1 py-2 md:flex flex flex-wrap">
          <div className="flex w-full text-center justify-center">
            <div className="">
              <img
                src={telephone}
                className="xl:w-4 xl:mt-1 mt-1 w-4"
              />
            </div>
            <div>
              <a href="tel:08046427999" style={{ fontFamily: "Figtree" }}>
                <p className="fontfamily text-black ml-2 font-semibold xl:text-lg lg:text-lg md:text-sm text-sm">
                  080 4642 7999
                </p>
              </a>
            </div>
          </div>

          <div className="flex mt-2 ">
            <div className="xl:ml-4 md:ml-4 ml-4 xl:mt-2 lg:mt-2 mt-1">
              <FaEnvelope className="text-blue-700 " size={16} />
            </div>
            <div>
              <a
                href="mailto:enquiry@trawelmart.com"
                style={{ fontFamily: "Figtree" }}
              >
                <p className="fontfamily text-black ml-2 -mt-111 font-semibold xl:text-lg lg:text-lg md:text-sm text-sm">
                  enquiry@trawelmart.com
                </p>
              </a>
            </div>
          </div>
        </div>



      </div>
    </div>
    </section>
  );
};

export default Navbar;
