import React, { useState } from "react";
import dubaibanner from "../assets/img/dubaibanner.jpg";
import { FaStar, FaWhatsapp } from "react-icons/fa";
import { FaCar } from "react-icons/fa";
import {
    FaBuilding,
    FaPassport,
    FaCamera,
    FaTicketAlt,
    FaPhone,
} from "react-icons/fa";
import dubai1 from "../assets/img/dubai1.jpeg";
import dubai2 from "../assets/img/dubai2.jpeg";
import dubai3 from "../assets/img/dubai3.jpeg";
import db1 from "../assets/img/db1.jpg";
import db2 from "../assets/img/db2.jpg";
import db3 from "../assets/img/db3.jpg";
import db4 from "../assets/img/db4.jpg";
import db5 from "../assets/img/db5.png";
import db6 from "../assets/img/db6.png";
import db7 from "../assets/img/db7.png";
import db8 from "../assets/img/db8.png";
import db9 from "../assets/img/db9.png";
import db10 from "../assets/img/db10.png";
import db11 from "../assets/img/db11.png";
import db12 from "../assets/img/db12.png";
import banner from "../assets/img/banner.jpeg";
import testi11 from "../assets/img/testi11.png";
import t2 from "../assets/img/t2.png";
import t3 from "../assets/img/t3.png";
import t4 from "../assets/img/t4.png";
import t5 from "../assets/img/t5.png";
import t6 from "../assets/img/t6.png";
import t7 from "../assets/img/t7.png";
import t8 from "../assets/img/t8.png";
import t9 from "../assets/img/t9.jpg";
import db1banner from "../assets/img/db1banner.jpeg";
import Footer from "./footer";
import Modal from "react-modal";
import axios from "axios";
import { toast } from "react-toastify";
import mobileviewdubai from "../assets/img/mobileviewdubai.jpeg";
import telephone from "../assets/img/telephone.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

Modal.setAppElement("#root");

const Homepage = () => {
    const stars = Array(5).fill(0);

    const [showModal, setShowModal] = React.useState(false);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        mobile: "",
        departure_city: "",
        travel_date: "",
        traveler_count: "",
        package_name: "",
        message: "",
    });

    const handleDateChange = (date) => {
        setFormData({ ...formData, travel_date: date.toISOString().slice(0, 10) });
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (!loading) {
            if (formData.name == "") {
                toast.error("Please enter your name");
            } else if (formData.email == "") {
                toast.error("Please enter your email");
            } else if (formData.mobile == "") {
                toast.error("Please enter your mobile number");
            } else if (formData.departure_city == "") {
                toast.error("Please enter your departure city");
            } else if (formData.travel_date == "") {
                toast.error("Please select your travel date");
            } else if (formData.traveler_count == "") {
                toast.error("Please enter your traveler count");
            } else if (formData.package_name == "") {
                toast.error("Please select the package");
            } else if (formData.message == "") {
                toast.error("Please enter your travel description");
            } else {
                setLoading(true);
                axios
                    .post(
                        "https://admin.trawelmart.com/admin/public/index.php/api/addDubaiEnquiry",
                        formData
                    )
                    .then((response) => {
                        if (response.status == 200) {
                            if (response.data.status) {
                                setFormData({
                                    name: "",
                                    email: "",
                                    mobile: "",
                                    departure_city: "",
                                    travel_date: "",
                                    traveler_count: "",
                                    package_name: "",
                                    message: "",
                                });
                                toast.success(response.data.message);
                                setShowModal(false);
                                setLoading(false);
                            } else {
                                setLoading(false);
                                toast.error(response.data.message);
                            }
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        toast.error("Some error occurred!");
                        setLoading(false);
                    });
            }
        }
    };

    return (
        <div>
            {/* desktop view */}
            <a
                href="https://wa.me/+919886745690"
                class=""
                target="_blank"
                style={{
                    position: "fixed",
                    width: "60px",
                    height: "60px",
                    bottom: "40px",
                    right: "30px",
                    background: "#25d366",
                    color: "#FFF",
                    borderRadius: "50px",
                    textAlign: "center",
                    boxShadow: "2px 2px 3px #999",
                    zIndex: "100",
                    fontSize: "30px",
                }}
            >
                <FaWhatsapp style={{ marginTop: "16px", marginLeft: "17px" }} />
            </a>
            <section className="overflow-hidden xl:block lg:block md:block hidden ">
                <div className="w-full xl:h-228 xl:mt-20 md:mt-20 lg:mt-16">
                    <img
                        src={db1banner}
                        alt="Dubai Banner"
                        className="w-full h-full object-cover shadow-md"
                    />
                    <div className="container mx-auto xl:px-24 lg:px-16 md:px-8 px-4 absolute inset-0 flex justify-left xl:pt-40 lg:pt-28 md:pt-24 pt-8 ">
                        <h1>
                            <p
                                className="line xl:block lg:hidden md:hidden text-white xl:text-5xl lg:text-3xl md:text-3xl text-xl fontfamily font-bold xl:py-1  uppercase"
                                style={{ fontFamily: "Figtree" }}
                            >
                                Dubai: Where adventure<br></br>meets luxury!
                            </p>

                            {/* for tab view  */}

                            <p
                                className="xl:hidden lg:block md:block text-white xl:text-5xl lg:text-3xl md:text-3xl text-xl fontfamily font-bold  uppercase"
                                style={{ fontFamily: "Figtree" }}
                            >
                                Dubai: Where adventure <br></br> meets luxury!
                            </p>

                            <p
                                className="text-white  xl:text-2xl lg:text-base md:text-sm text-xs font-serif xl:pt-2 font-semibold "
                                style={{ fontFamily: "Figtree" }}
                            >
                                Discover the Ultimate Thrills of Dubai with Trawelmart!
                            </p>

                            <div className="relative inline-block xl:mt-4 lg:mt-4 md:mt-2">
                                <p className=" text-white xl:text-xl lg:text-xl md:text-base text-lg fontfamily xl:mt-2 font-bold xl:py-1 py-1 uppercase"
                                    style={{ fontFamily: "Figtree" }}>
                                    Get Upto <span style={{ color: "#F49A1F" }}>20%</span> Off on all <span style={{ color: "#F49A1F" }}>Dubai</span> tour packages
                                </p>
                                <div className="absolute bottom-0 left-0 w-full h-0.5 bg-gradient-to-r from-transparent via-white to-transparent"></div>
                            </div>

                            {/* <p className=" text-white xl:text-base lg:text-sm md:text-xs text-base fontfamily xl:mt-2 font-base xl:py-1 pt-2 uppercase "
                                style={{ fontFamily: "Figtree" }}>
                                <button className="rounded-md xl:px-4 lg:px-4 md:px-4 xl:py-2 lg:py-2 md:py-2 uppercase text-white font-bold"
                                    style={{ backgroundColor: "#F49A1F" }}>
                                    enquiry now
                                </button>
                            </p> */}

                            <p className=" text-white xl:text-base lg:text-sm md:text-xs text-base fontfamily xl:mt-2 font-base xl:py-1 pt-2 uppercase "
                                style={{ fontFamily: "Figtree" }}>
                                <button
                                    className="rounded-md xl:px-4 lg:px-4 md:px-4 xl:py-2 lg:py-2 md:py-2 uppercase text-white font-bold"
                                    type="button"
                                    onClick={() => {
                                        setShowModal(true);
                                        setFormData({
                                            ...formData,
                                            package_name: "Discover Dubai Delights",
                                        });
                                    }}
                                    style={{ backgroundColor: "#F49A1F" }}
                                >
                                    Enquiry Now
                                </button>
                            </p>

                            <p className="xl:block lg:hidden md:hidden text-white xl:text-base lg:text-sm md:text-xs text-base fontfamily xl:mt-2 font-base xl:py-1 py-2 ml-2 uppercase"
                                style={{ fontFamily: "Figtree" }}>
                                <span className="inline-flex items-center justify-center w-12 h-12 rounded-full text-white mr-2">
                                    <i className="fab fa-google text-3xl pl-12"></i>
                                    <p className="xl:text-sm pl-4"> 4.6/5.0 <br></br> ratings </p>
                                </span>
                            </p>


                            {/* for tab view  */}

                            <p className="xl:hidden lg:block md:block text-white xl:text-base lg:text-sm md:text-xs text-base fontfamily xl:mt-2 font-base xl:py-1 pt-1 lg:ml-1 uppercase"
                                style={{ fontFamily: "Figtree" }}>
                                <span className="inline-flex items-center justify-center w-12 h-12 rounded-full text-white mr-2">
                                    <i className="fab fa-google lg:text-3xl md:text-2xl pl-12"></i>
                                    <p className="xl:text-xs pl-2"> 4.6/5.0 <br></br> ratings </p>
                                </span>
                            </p>

                        </h1>
                    </div>
                </div>
            </section>

            {/* mobile view */}

            <section className="xl:hiden lg:hidden md:hidden block overflow-hidden container mx-auto">
                <div className="relative w-full xl:h-228 flex items-center justify-center mt-0">
                    <img
                        src={mobileviewdubai}
                        alt="Dubai Banner"
                        className="w-full h-full object-cover  shadow-md"
                    />
                    <div className="absolute inset-0 flex justify-left xl:pt-32 lg:pt-24 md:pt-16 pt-4">
                        <h1>
                            <p
                                className="text-white xl:text-8xl lg:text-7xl md:text-6xl text-base xl:ml-32 lg:ml-32 md:ml-20 ml-4 font-serif font-bold  uppercase "
                                style={{ fontFamily: "Figtree", lineHeight: '20px' }}
                            >
                                Dubai: Where adventure<br></br> meets luxury!
                            </p>
                            <p
                                className="text-white  xl:text-xl lg:text-base md:text-sm text-xs mt-1 font-serif font-normal xl:ml-32 lg:ml-32 md:ml-20 ml-4"
                                style={{ fontFamily: "Figtree", lineHeight: '15px' }}
                            >
                                Discover the Ultimate Thrills of Dubai <br></br>with Trawelmart!
                            </p>

                            <div className="relative inline-block xl:mt-4 lg:mt-4 md:mt-2 mt-1 ml-4">
                                <p className=" text-white xl:text-xl lg:text-xl md:text-base text-xs fontfamily xl:mt-2 font-normal xl:py-1 py-1 uppercase"
                                    style={{ fontFamily: "Figtree" }}>
                                    Get Upto <span style={{ color: "#F49A1F" }}>20%</span> Off
                                    {/* <br></br>on all <span style={{ color: "#F49A1F" }}>Dubai</span> tour packages */}
                                </p>
                                <div className="absolute bottom-0 left-0 w-full h-0.5 bg-gradient-to-r from-transparent via-white to-transparent"></div>
                            </div>

                            {/* <p className=" text-white xl:text-base lg:text-sm md:text-xs text-xs fontfamily ml-4 xl:mt-2 font-base xl:py-1 pt-2 uppercase "
                                style={{ fontFamily: "Figtree" }}>
                                <button className="rounded-md xl:px-4 lg:px-4 md:px-4 px-3 xl:py-2 lg:py-2 md:py-2 py-1 uppercase text-white font-bold"
                                    style={{ backgroundColor: "#F49A1F" }}>
                                    enquiry now
                                </button>
                            </p> */}

                            <p className="text-white xl:text-base lg:text-sm md:text-xs text-xs fontfamily ml-4 xl:mt-2 font-base xl:py-1 pt-2 uppercase "
                                style={{ fontFamily: "Figtree" }}>
                                <button
                                    className="rounded-md xl:px-4 lg:px-4 md:px-4 px-3 xl:py-2 lg:py-2 md:py-2 py-1 uppercase text-white font-bold"
                                    type="button"
                                    onClick={() => {
                                        setShowModal(true);
                                        setFormData({
                                            ...formData,
                                            package_name: "Discover Dubai Delights",
                                        });
                                    }}
                                    style={{ backgroundColor: "#F49A1F" }}
                                >
                                    Enquiry Now
                                </button>
                            </p>

                        </h1>
                    </div>
                </div>
            </section>

            {/* desktop view */}

            <section className="overflow-hidden xl:block lg:block md:block block container xl:py-2 lg:py-2 md:py-2 py-2 mx-auto">
                <div className="flex justify-center items-center xl:mt-12 lg:mt-4 md:mt-4 mt-4 ">
                    <h1 className="">
                        <p
                            className="text-center xl:text-4xl lg:text-3xl md:text-2xl text-xl uppercase font-bold"
                            style={{ fontFamily: "Figtree" }}
                        >
                            Ultimate Vacation Awaits!
                        </p>
                        <p
                            className="xl:text-xl lg:text-xl md:text-base text-sm  xl:mt-2 lg:mt-1 md:mt-1 mt-1 
                        text-center"
                            style={{ fontFamily: "Figtree" }}
                        >
                            100% Customized Trips with Our Premier Packages!
                        </p>
                    </h1>
                </div>
            </section>

            {/* desktop view */}

            <section className="overflow-hidden xl:block lg:block md:block block container xl:mt-6 xl:py-0 lg:py-0 md:py-0 py-0 mx-auto">
                <div className="xl:mt-4 lg:mt-4 md:mt-4 mt-4 xl:px-0 px-2">
                    <div className="grid xl:grid-cols-4 lg:grid-cols-2 md:grid-cols-2 xl:gap-4 lg:gap-2 md:gap-2 gap-4 xl:px-24 lg:px-16 md:px-8">
                        {/* first grid */}
                        <div>
                            <img
                                src={db1}
                                alt="dubai1"
                                className=" w-full xl:h-52 lg:h-48 md:h-48 rounded-t"
                            />
                            <div className="px-4 pt-4 bg-white xl:pt-2 lg:pt-2 border  md:pt-2  rounded-md">
                                <div
                                    className=" xl:text-2xl text-left"
                                    style={{ fontFamily: "Figtree" }}
                                >
                                    <div className="flex">
                                        <p className="mt-2  text-sm text-blue-700 font-semibold uppercase">
                                            4 Nights  5 Days
                                        </p>
                                        <p className="mt-2 text-sm xl:ml-20 lg:ml-48 md:ml-24 ml-32 text-blue-700 font-semibold uppercase ">
                                            <i className="fas fa-star text-yellow-500 mr-1"></i>
                                            4.9 (2183)
                                        </p>
                                    </div>
                                    <p
                                        className="mt-1 xl:text-2xl lg:text-2xl md:text-xl text-2xl"
                                        style={{
                                            color: "#4b4545",
                                            fontWeight: "500",
                                        }}
                                    >
                                        Discover Dubai Delights
                                    </p>
                                    <p className="xl:text-sm lg:text-sm md:text-xs text-xs font-semibold uppercase"
                                        style={{
                                            color: "#4b4545",
                                            fontWeight: "500",
                                        }}>
                                        Dubai (4N)
                                    </p>
                                </div>
                                <div
                                    className="  xl:text-base lg:text-sm md:text-xs text-sm text-left"
                                    style={{ fontFamily: "Figtree" }}
                                >
                                    {/* <p className='xl:mt-4 lg:mt-4 md:mt-4'>Inclusions</p> */}
                                    <div className="flex items-center justify-start xl:mt-4 lg:mt-4 md:mt-2 mt-4 ">
                                        <div
                                            className=" rounded-full p-2 border"
                                            style={{ background: "#f0f8ff" }}
                                        >
                                            <FaBuilding className="text-blue-700 " size={17} />
                                        </div>
                                        <div
                                            className=" rounded-full p-2 ml-2 border"
                                            style={{ background: "#f0f8ff" }}
                                        >
                                            <FaCar className="text-blue-700 " size={17} />
                                        </div>
                                        <div
                                            className=" rounded-full p-2 ml-2 border"
                                            style={{ background: "#f0f8ff" }}
                                        >
                                            <FaCamera className="text-blue-700 " size={17} />
                                        </div>
                                        <div
                                            className=" rounded-full p-2 ml-2 border"
                                            style={{ background: "#f0f8ff" }}
                                        >
                                            <img src="https://i.ibb.co/ZfGzscB/tickett.png" className="w-4 h-4 text-blue-700" />
                                        </div>
                                        <div
                                            className=" rounded-full p-2 mr-2 ml-2 border"
                                            style={{ background: "#f0f8ff" }}
                                        >
                                            <FaPassport className="text-blue-700 " size={16} />
                                        </div>
                                    </div>
                                </div>
                                <div className="xl:text-2xl lg:text-2xl md:text-xl text-2xl">
                                    <p
                                        className="mt-5 font-bold"
                                        style={{ fontFamily: "Figtree" }}
                                    >
                                        INR 39,000/-{" "}
                                        <span className="xl:text-sm font-normal">per person</span>{" "}
                                    </p>
                                    <p className="xl:text-md lg:text-md md:text-md text-sm line-through">
                                        INR 46,800
                                    </p>
                                </div>

                                <div className="flex justify-between items-center text-sm text-center mt-2">
                                    <div className="xl:w-2/12 w-22/12 xl:pl-0 lg:pl-4">
                                        <div className="border rounded border-blue-700 py-4 px-3">
                                            <a href="tel:08046427999">
                                                <img src={telephone} className="xl:w-4 ml-111 w-4" />
                                            </a>
                                        </div>
                                    </div>
                                    <button
                                        className="ml-2 xl:w-10/12 w-10/12 bg-blue-600 hover:bg-blue-700 text-white py-3 font-semibold text-base px-4 mt-4 xl:mb-4 lg:mb-4 md:mb-4 mb-4 rounded focus:outline-none focus:shadow-outline"
                                        type="button"
                                        onClick={() => {
                                            setShowModal(true);
                                            setFormData({
                                                ...formData,
                                                package_name: "Discover Dubai Delights",
                                            });
                                        }}
                                        style={{ fontFamily: "Figtree" }}
                                    >
                                        Enquiry Now
                                    </button>
                                    {showModal ? (
                                        <>
                                            <div className="justify-center items-center mt-12  overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                                                <div className="relative w-auto my-6 mx-auto max-w-xl">
                                                    {/*content*/}
                                                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                                        {/*header*/}
                                                        <div className="  items-start justify-between p-5  border-blueGray-200 rounded-t">
                                                            <button
                                                                className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                                                onClick={() => setShowModal(false)}
                                                            >
                                                                <span className="bg-white rounded-full p-2 text-black h-6 w-6 text-2xl block outline-none focus:outline-none ml-4">
                                                                    <img src="https://i.ibb.co/wccqSKQ/close.png"></img>
                                                                </span>
                                                            </button>
                                                            <h3
                                                                className="ml-12 text-2xl text-black text-center font-semibold uppercase"
                                                                style={{ fontFamily: "Figtree" }}
                                                            >
                                                                Enquiry Now
                                                            </h3>
                                                            <div
                                                                className="justify-center text-center "
                                                                style={{ textAlign: "-webkit-center" }}
                                                            >
                                                                <hr className="border-2 border-blue-700 xl:w-2/12 justify-center text-center mt-2"></hr>
                                                            </div>
                                                        </div>
                                                        {/*body*/}
                                                        <div className="relative p-6 xl:w-12/12">
                                                            <form className="">
                                                                <div
                                                                    className="xl:w-12/12"
                                                                    style={{ fontFamily: "Figtree" }}
                                                                >
                                                                    <input
                                                                        type="text"
                                                                        placeholder="Full Name"
                                                                        className="block w-full border-neutral-200  bg-white
                                                                      
                                                                      text-sm font-normal h-11 px-4 py-3 bg-white text-black border 
                                                                      border-slate-300 px-3 py-1 h-12 rounded "
                                                                        onChange={(e) =>
                                                                            setFormData({
                                                                                ...formData,
                                                                                name: e.target.value,
                                                                            })
                                                                        }
                                                                        value={formData.name}
                                                                    ></input>
                                                                </div>
                                                                <div
                                                                    className="xl:w-12/12"
                                                                    style={{ fontFamily: "Figtree" }}
                                                                >
                                                                    <input
                                                                        type="email"
                                                                        placeholder="Email"
                                                                        className="block w-full border-neutral-200  bg-white 
                                                                       
                                                                      text-sm font-normal h-11 px-4 py-3 bg-white text-black border 
                                                                      border-slate-300 px-3 py-1 h-12 rounded mt-6"
                                                                        onChange={(e) =>
                                                                            setFormData({
                                                                                ...formData,
                                                                                email: e.target.value,
                                                                            })
                                                                        }
                                                                        value={formData.email}
                                                                    ></input>
                                                                </div>
                                                                <div
                                                                    className="xl:w-12/12"
                                                                    style={{ fontFamily: "Figtree" }}
                                                                >
                                                                    <input
                                                                        type="number"
                                                                        placeholder="Phone Number"
                                                                        className="block w-full border-neutral-200  bg-white 
                                                                      
                                                                      text-sm font-normal h-11 px-4 py-3 bg-white text-black border 
                                                                      border-slate-300 px-3 py-1 h-12 rounded mt-6"
                                                                        onChange={(e) =>
                                                                            setFormData({
                                                                                ...formData,
                                                                                mobile: e.target.value,
                                                                            })
                                                                        }
                                                                        value={formData.mobile}
                                                                        min={1}
                                                                    ></input>
                                                                </div>
                                                                <div
                                                                    className="xl:w-12/12"
                                                                    style={{ fontFamily: "Figtree" }}
                                                                >
                                                                    <input
                                                                        type="text"
                                                                        placeholder="Departure City"
                                                                        className="block w-full border-neutral-200  bg-white 
                                                                      
                                                                      text-sm font-normal h-11 px-4 py-3 bg-white text-black border 
                                                                      border-slate-300 px-3 py-1 h-12 rounded mt-6"
                                                                        onChange={(e) =>
                                                                            setFormData({
                                                                                ...formData,
                                                                                departure_city: e.target.value,
                                                                            })
                                                                        }
                                                                        value={formData.departure_city}
                                                                    ></input>
                                                                </div>

                                                                <div
                                                                    className="xl:w-12/12 flex justify-between"
                                                                    style={{ fontFamily: "Figtree" }}
                                                                >
                                                                    <DatePicker
                                                                        selected={formData.travel_date ? new Date(formData.travel_date) : null}
                                                                        onChange={handleDateChange}
                                                                        id="travelDateInput"
                                                                        placeholderText="Travel Date"
                                                                        minDate={new Date()}
                                                                        dateFormat={'d-M-Y'}
                                                                        popperPlacement="top"
                                                                        className="ml-2 block w-full border-neutral-200  bg-white
                                                                     
                                                                      text-sm font-normal h-11 px-4 py-3 bg-white text-black border 
                                                                      border-slate-300 px-3 py-1 h-12 rounded mt-6"
                                                                    />
                                                                    &nbsp;&nbsp;&nbsp;
                                                                    <input
                                                                        type="number"
                                                                        placeholder="No of Passengers"
                                                                        className="ml-2 block w-full border-neutral-200  bg-white
                                                                     
                                                                      text-sm font-normal h-11 px-4 py-3 bg-white text-black border 
                                                                      border-slate-300 px-3 py-1 h-12 rounded mt-6"
                                                                        onChange={(e) =>
                                                                            setFormData({
                                                                                ...formData,
                                                                                traveler_count: e.target.value,
                                                                            })
                                                                        }
                                                                        value={formData.traveler_count}
                                                                        min={1}
                                                                    ></input>
                                                                </div>

                                                                <div
                                                                    className="xl:w-12/12 flex justify-between"
                                                                    style={{ fontFamily: "Figtree" }}
                                                                >
                                                                    <textarea
                                                                        placeholder="Travel Description"
                                                                        className="block w-full border-neutral-200  bg-white 
                                                                    border-slate-300
                                                                      text-sm font-normal h-24 px-4 py-3 bg-white text-black border 
                                                                       px-3 py-1 h-12 rounded mt-6"
                                                                        onChange={(e) =>
                                                                            setFormData({
                                                                                ...formData,
                                                                                message: e.target.value,
                                                                            })
                                                                        }
                                                                        value={formData.message}
                                                                    ></textarea>
                                                                </div>

                                                                <div
                                                                    className="cursor-pointer bg-blue-700 py-3 xl:w-12/12 text-white mt-4 rounded font-semibold"
                                                                    style={{ fontFamily: "Figtree" }}
                                                                    onClick={handleFormSubmit}
                                                                >
                                                                    {loading
                                                                        ? "Sending Enquiry"
                                                                        : "Connect With an Expert"}
                                                                </div>
                                                            </form>
                                                        </div>
                                                        {/*footer*/}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="fixed inset-0 z-40 bg-black opacity-80"></div>
                                        </>
                                    ) : null}
                                </div>
                            </div>
                        </div>

                        {/* second grid inter*/}

                        <div>
                            <img
                                src={db2}
                                alt="dubai1"
                                className="rounded-t w-full xl:h-52 lg:h-48 md:h-48 "
                            />
                            <div className="px-4 pt-4 bg-white xl:pt-2 lg:pt-2 border  md:pt-2 rounded-md">
                                <div
                                    className="  xl:text-xl text-left"
                                    style={{ fontFamily: "Figtree" }}
                                >
                                    <div className="flex">
                                        <p className="mt-2  text-sm text-blue-700 font-semibold uppercase">
                                            5 Nights  6 Days
                                        </p>
                                        <p className="mt-2 text-sm xl:ml-20 lg:ml-48 md:ml-24 ml-32 text-blue-700 font-semibold uppercase ">
                                            <i className="fas fa-star text-yellow-500 mr-1"></i>
                                            4.8 (1103)
                                        </p>
                                    </div>

                                    <p
                                        className="mt-1 xl:text-2xl lg:text-2xl md:text-xl text-2xl"
                                        style={{
                                            color: "#4b4545",
                                            fontWeight: "500",
                                        }}
                                    >
                                        Experience Dubai Magic
                                    </p>

                                    <p className="xl:text-sm lg:text-sm md:text-xs text-xs font-semibold uppercase"
                                        style={{
                                            color: "#4b4545",
                                            fontWeight: "500",
                                        }}>
                                        Dubai (5N)
                                    </p>

                                </div>

                                <div
                                    className="  xl:text-base lg:text-sm md:text-xs text-sm text-center"
                                    style={{ fontFamily: "Figtree" }}
                                >
                                    {/* <p className='xl:mt-4 lg:mt-4 md:mt-4'>Inclusions</p> */}
                                    <div className="flex items-center justify-start xl:mt-4 lg:mt-4  md:mt-2 mt-4 ">
                                        <div
                                            className=" rounded-full p-2 border"
                                            style={{ background: "#f0f8ff" }}
                                        >
                                            <FaBuilding className="text-blue-700 " size={17} />
                                        </div>
                                        <div
                                            className=" rounded-full p-2 ml-2 border"
                                            style={{ background: "#f0f8ff" }}
                                        >
                                            <FaCar className="text-blue-700 " size={17} />
                                        </div>
                                        <div
                                            className=" rounded-full p-2 ml-2 border"
                                            style={{ background: "#f0f8ff" }}
                                        >
                                            <FaCamera className="text-blue-700 " size={17} />
                                        </div>
                                        <div
                                            className=" rounded-full p-2 ml-2 border"
                                            style={{ background: "#f0f8ff" }}
                                        >
                                            <img src="https://i.ibb.co/ZfGzscB/tickett.png" className="w-4 h-4 text-blue-700" />
                                        </div>
                                        <div
                                            className=" rounded-full p-2 mr-2 ml-2 border"
                                            style={{ background: "#f0f8ff" }}
                                        >
                                            <FaPassport className="text-blue-700 " size={16} />
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className=" xl:text-2xl lg:text-2xl md:text-xl text-2xl"
                                    style={{ fontFamily: "Figtree" }}
                                >
                                    <p
                                        className="mt-5 font-bold"
                                        style={{ fontFamily: "Figtree" }}
                                    >
                                        INR 49,000/-{" "}
                                        <span className="xl:text-sm font-normal">per person</span>
                                    </p>

                                    <p className="xl:text-md lg:text-md md:text-md text-sm line-through">
                                        INR 58,800
                                    </p>

                                </div>

                                <div className="flex justify-between items-center text-sm text-center mt-2">
                                    <div className="xl:w-2/12 w-22/12 xl:pl-0 lg:pl-4">
                                        <div className="border center-items rounded border-blue-700 py-4 px-3">
                                            <a href="tel:08046427999">
                                                <img src={telephone} className="xl:w-4 ml-111 w-4" />
                                            </a>
                                        </div>
                                    </div>
                                    <button
                                        className="ml-2 xl:w-10/12 w-10/12 bg-blue-600 hover:bg-blue-700 text-white py-3 font-semibold text-base px-4 mt-4 xl:mb-4 lg:mb-4 md:mb-4 mb-4 rounded focus:outline-none focus:shadow-outline"
                                        type="button"
                                        onClick={() => {
                                            setShowModal(true);
                                            setFormData({
                                                ...formData,
                                                package_name: "Experience Dubai Magic",
                                            });
                                        }}
                                        style={{ fontFamily: "Figtree" }}
                                    >
                                        Enquiry Now
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* third grid */}

                        <div>
                            <img
                                src={db3}
                                alt="dubai1"
                                className="rounded-t w-full xl:h-52 lg:h-48 md:h-48"
                            />
                            <div className="px-4 pt-4 bg-white xl:pt-2 lg:pt-2 border  md:pt-2 rounded-md">
                                <div
                                    className="  xl:text-xl text-left"
                                    style={{ fontFamily: "Figtree" }}
                                >
                                    <div className="flex">
                                        <p className="mt-2  text-sm text-blue-700 font-semibold uppercase">
                                            6 Nights  7 Days
                                        </p>
                                        <p className="mt-2 text-sm xl:ml-20 lg:ml-48 md:ml-24 ml-32 text-blue-700 font-semibold uppercase ">
                                            <i className="fas fa-star text-yellow-500 mr-1"></i>
                                            4.9 (1296)
                                        </p>
                                    </div>
                                    <p
                                        className="mt-1 xl:text-2xl lg:text-2xl md:text-xl text-2xl"
                                        style={{
                                            color: "#4b4545",
                                            fontWeight: "500",
                                        }}
                                    >
                                        Unveil Dubai Wonders
                                    </p>
                                    <p className="xl:text-sm lg:text-sm md:text-xs text-xs font-semibold uppercase"
                                        style={{
                                            color: "#4b4545",
                                            fontWeight: "500",
                                        }}>
                                        Dubai (6N)
                                    </p>

                                </div>
                                <div
                                    className="  xl:text-base lg:text-sm md:text-xs text-sm text-center"
                                    style={{ fontFamily: "Figtree" }}
                                >
                                    {/* <p className='xl:mt-4 lg:mt-4 md:mt-4'>Inclusions</p> */}
                                    <div className="flex items-center justify-start xl:mt-4 lg:mt-4 md:mt-2 mt-4 ">
                                        <div
                                            className=" rounded-full p-2  border"
                                            style={{ background: "#f0f8ff" }}
                                        >
                                            <FaBuilding className="text-blue-700 " size={17} />
                                        </div>
                                        <div
                                            className=" rounded-full p-2 ml-2 border"
                                            style={{ background: "#f0f8ff" }}
                                        >
                                            <FaCar className="text-blue-700 " size={17} />
                                        </div>
                                        <div
                                            className=" rounded-full p-2 ml-2 border"
                                            style={{ background: "#f0f8ff" }}
                                        >
                                            <FaCamera className="text-blue-700 " size={17} />
                                        </div>
                                        <div
                                            className=" rounded-full p-2 ml-2 border"
                                            style={{ background: "#f0f8ff" }}
                                        >
                                            <img src="https://i.ibb.co/ZfGzscB/tickett.png" className="w-4 h-4 text-blue-700" />
                                        </div>
                                        <div
                                            className=" rounded-full p-2 mr-2 ml-2 border"
                                            style={{ background: "#f0f8ff" }}
                                        >
                                            <FaPassport className="text-blue-700 " size={16} />
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className=" xl:text-2xl lg:text-2xl md:text-xl text-2xl"
                                    style={{ fontFamily: "Figtree" }}
                                >
                                    <p
                                        className="mt-5 font-bold"
                                        style={{ fontFamily: "Figtree" }}
                                    >
                                        INR 59,000/-{" "}
                                        <span className="xl:text-sm font-normal">per person</span>
                                    </p>
                                    <p className="xl:text-md lg:text-md md:text-md text-sm line-through">
                                        INR 70,800
                                    </p>

                                </div>

                                <div className="flex justify-between items-center text-sm text-center mt-2">
                                    <div className="xl:w-2/12 w-22/12 xl:pl-0 lg:pl-4">
                                        <div className="border rounded border-blue-700 py-4 px-3">
                                            <a href="tel:08046427999">
                                                <img src={telephone} className="xl:w-4 ml-111 w-4" />
                                            </a>
                                        </div>
                                    </div>
                                    <button
                                        className="ml-2 xl:w-10/12 w-10/12 bg-blue-600 hover:bg-blue-700 text-white py-3 font-semibold text-base px-4 mt-4 xl:mb-4 lg:mb-4 md:mb-4 mb-4 rounded focus:outline-none focus:shadow-outline"
                                        type="button"
                                        onClick={() => {
                                            setShowModal(true);
                                            setFormData({
                                                ...formData,
                                                package_name: "Unveil Dubai Wonders",
                                            });
                                        }}
                                        style={{ fontFamily: "Figtree" }}
                                    >
                                        Enquiry Now
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* fourth grid */}

                        <div>
                            <img
                                src={db4}
                                alt="dubai1"
                                className="rounded-t w-full xl:h-52 lg:h-48 md:h-48"
                            />
                            <div className="px-4 pt-4 bg-white xl:pt-2 lg:pt-2 border md:pt-2 rounded-md">
                                <div
                                    className="  xl:text-xl text-left"
                                    style={{ fontFamily: "Figtree" }}
                                >
                                    <div className="flex">
                                        <p className="mt-2  text-sm text-blue-700 font-semibold uppercase">
                                            5 Nights  6 Days
                                        </p>
                                        <p className="mt-2 text-sm xl:ml-20 lg:ml-48 md:ml-24 ml-32 text-blue-700 font-semibold uppercase ">
                                            <i className="fas fa-star text-yellow-500 mr-1"></i>
                                            4.9 (1563)
                                        </p>
                                    </div>

                                    <p
                                        className="mt-1 xl:text-2xl lg:text-2xl md:text-xl text-2xl"
                                        style={{
                                            color: "#4b4545",
                                            fontWeight: "500",
                                        }}
                                    >
                                        Uncover Dubai Secrets
                                    </p>
                                    <p className="xl:text-sm lg:text-sm md:text-xs text-xs font-semibold uppercase"
                                        style={{
                                            color: "#4b4545",
                                            fontWeight: "500",
                                        }}>
                                        Dubai (5N)
                                    </p>

                                </div>
                                <div
                                    className="  xl:text-base lg:text-sm md:text-xs text-sm text-center"
                                    style={{ fontFamily: "Figtree" }}
                                >
                                    {/* <p className='xl:mt-4 lg:mt-4 md:mt-4'>Inclusions</p> */}
                                    <div className="flex items-center justify-start xl:mt-4 lg:mt-4 md:mt-2 mt-4 ">
                                        <div
                                            className=" rounded-full p-2  border"
                                            style={{ background: "#f0f8ff" }}
                                        >
                                            <FaBuilding className="text-blue-700 " size={17} />
                                        </div>
                                        <div
                                            className=" rounded-full p-2 ml-2 border"
                                            style={{ background: "#f0f8ff" }}
                                        >
                                            <FaCar className="text-blue-700 " size={17} />
                                        </div>
                                        <div
                                            className=" rounded-full p-2 ml-2 border"
                                            style={{ background: "#f0f8ff" }}
                                        >
                                            <FaCamera className="text-blue-700 " size={17} />
                                        </div>
                                        <div
                                            className=" rounded-full p-2 ml-2 border"
                                            style={{ background: "#f0f8ff" }}
                                        >
                                            <img src="https://i.ibb.co/ZfGzscB/tickett.png" className="w-4 h-4 text-blue-700" />
                                        </div>
                                        <div
                                            className=" rounded-full p-2 mr-2 ml-2 border"
                                            style={{ background: "#f0f8ff" }}
                                        >
                                            <FaPassport className="text-blue-700 " size={16} />
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className=" xl:text-2xl lg:text-2xl md:text-xl text-2xl"
                                    style={{ fontFamily: "Figtree" }}
                                >
                                    <p
                                        className="mt-5 font-bold"
                                        style={{ fontFamily: "Figtree" }}
                                    >
                                        INR 55,000/-{" "}
                                        <span className="xl:text-sm font-normal">per person</span>
                                    </p>

                                    <p className="xl:text-md lg:text-md md:text-md text-sm line-through">
                                        INR 66,000
                                    </p>

                                </div>

                                <div className="flex justify-between items-center text-sm text-center mt-2">
                                    <div className="xl:w-2/12 w-22/12 xl:pl-0 lg:pl-4">
                                        <div className="border rounded border-blue-700 py-4 px-3">
                                            <a href="tel:08046427999">
                                                <img src={telephone} className="xl:w-4 ml-111 w-4" />
                                            </a>
                                        </div>
                                    </div>
                                    <button
                                        className="ml-2 xl:w-10/12 w-10/12 bg-blue-600 hover:bg-blue-700 text-white py-3 font-semibold text-base px-4 mt-4 xl:mb-4 lg:mb-4 md:mb-4 mb-4 rounded focus:outline-none focus:shadow-outline"
                                        type="button"
                                        onClick={() => {
                                            setShowModal(true);
                                            setFormData({
                                                ...formData,
                                                package_name: "Uncover Dubai Secrets",
                                            });
                                        }}
                                        style={{ fontFamily: "Figtree" }}
                                    >
                                        Enquiry Now
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* 2nd list */}

            <section className="overflow-hidden xl:block lg:block md:block block container xl:py-0 lg:py-0 md:py-0 py-0 mx-auto">
                <div className="xl:mt-4 lg:mt-4 md:mt-4 mt-4 xl:px-0 px-2">
                    <div className="grid xl:grid-cols-4 lg:grid-cols-2 md:grid-cols-2 xl:gap-4 lg:gap-2 md:gap-2 gap-4 xl:px-24 lg:px-16 md:px-8">
                        {/* first grid */}

                        <div>
                            <img
                                src={db5}
                                alt="dubai1"
                                className="rounded-t w-full xl:h-52 lg:h-48 md:h-48"
                            />
                            <div className="px-4 pt-4 bg-white xl:pt-2 lg:pt-2 border md:pt-2 rounded-md">
                                <div
                                    className="  xl:text-xl text-left"
                                    style={{ fontFamily: "Figtree" }}
                                >
                                    <div className="flex">
                                        <p className="mt-2 text-sm text-blue-700 font-semibold uppercase">
                                            6 Nights  7 Days
                                        </p>
                                        <p className="mt-2 text-sm xl:ml-20 lg:ml-48 md:ml-24 ml-32 text-blue-700 font-semibold uppercase ">
                                            <i className="fas fa-star text-yellow-500 mr-1"></i>
                                            4.8 (1783)
                                        </p>
                                    </div>

                                    <p
                                        className="mt-1 xl:text-2xl lg:text-2xl md:text-xl text-2xl"
                                        style={{
                                            color: "#4b4545",
                                            fontWeight: "500",
                                        }}
                                    >
                                        Dubai with Palm Atlantis
                                    </p>
                                    <p className="xl:text-sm lg:text-sm md:text-xs text-xs font-semibold uppercase"
                                        style={{
                                            color: "#4b4545",
                                            fontWeight: "500",
                                        }}>
                                        Dubai (5N) , Palm Atlantis (1N)
                                    </p>

                                </div>

                                <div
                                    className="  xl:text-base lg:text-sm md:text-xs text-sm text-center"
                                    style={{ fontFamily: "Figtree" }}
                                >
                                    {/* <p className='xl:mt-4 lg:mt-4 md:mt-4'>Inclusions</p> */}
                                    <div className="flex items-center justify-start xl:mt-4 lg:mt-4 md:mt-2 mt-4 ">
                                        <div
                                            className=" rounded-full p-2  border"
                                            style={{ background: "#f0f8ff" }}
                                        >
                                            <FaBuilding className="text-blue-700 " size={17} />
                                        </div>
                                        <div
                                            className=" rounded-full p-2 ml-2 border"
                                            style={{ background: "#f0f8ff" }}
                                        >
                                            <FaCar className="text-blue-700 " size={17} />
                                        </div>
                                        <div
                                            className=" rounded-full p-2 ml-2 border"
                                            style={{ background: "#f0f8ff" }}
                                        >
                                            <FaCamera className="text-blue-700 " size={17} />
                                        </div>
                                        <div
                                            className=" rounded-full p-2 ml-2 border"
                                            style={{ background: "#f0f8ff" }}
                                        >
                                            <img src="https://i.ibb.co/ZfGzscB/tickett.png" className="w-4 h-4 text-blue-700" />
                                        </div>
                                        <div
                                            className=" rounded-full p-2 mr-2 ml-2 border"
                                            style={{ background: "#f0f8ff" }}
                                        >
                                            <FaPassport className="text-blue-700 " size={16} />
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className=" xl:text-2xl lg:text-2xl md:text-xl text-2xl"
                                    style={{ fontFamily: "Figtree" }}
                                >
                                    <p
                                        className="mt-5 font-bold"
                                        style={{ fontFamily: "Figtree" }}
                                    >
                                        INR 78,000/-{" "}
                                        <span className="xl:text-sm font-normal">per person</span>
                                    </p>

                                    <p className="xl:text-md lg:text-md md:text-md text-sm line-through">
                                        INR 93,600
                                    </p>

                                </div>

                                <div className="flex justify-between items-center text-sm text-center mt-2">
                                    <div className="xl:w-2/12 w-22/12 xl:pl-0 lg:pl-4">
                                        <div className="border rounded border-blue-700 py-4 px-3">
                                            <a href="tel:08046427999">
                                                <img src={telephone} className="xl:w-4 ml-111 w-4" />
                                            </a>
                                        </div>
                                    </div>
                                    <button
                                        className="ml-2 xl:w-10/12 w-10/12 bg-blue-600 hover:bg-blue-700 text-white py-3 font-semibold text-base px-4 mt-4 xl:mb-4 lg:mb-4 md:mb-4 mb-4 rounded focus:outline-none focus:shadow-outline"
                                        type="button"
                                        onClick={() => {
                                            setShowModal(true);
                                            setFormData({
                                                ...formData,
                                                package_name: "Marvel Dubai Attractions",
                                            });
                                        }}
                                        style={{ fontFamily: "Figtree" }}
                                    >
                                        Enquiry Now
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* second grid inter*/}

                        <div>
                            <img
                                src={db6}
                                alt="dubai1"
                                className="rounded-t w-full xl:h-52 lg:h-48 md:h-48"
                            />
                            <div className="px-4 pt-4 bg-white xl:pt-2 lg:pt-2 border  md:pt-2 rounded-md">
                                <div
                                    className="  xl:text-xl text-left"
                                    style={{ fontFamily: "Figtree" }}
                                >
                                    <div className="flex">
                                        <p className="mt-2  text-sm text-blue-700 font-semibold uppercase">
                                            6 Nights  7 Days
                                        </p>
                                        <p className="mt-2 text-sm xl:ml-20 lg:ml-48 md:ml-24 ml-32 text-blue-700 font-semibold uppercase ">
                                            <i className="fas fa-star text-yellow-500 mr-1"></i>
                                            4.8 (1306)
                                        </p>
                                    </div>

                                    <p
                                        className="mt-1 xl:text-2xl lg:text-2xl md:text-xl text-2xl"
                                        style={{
                                            color: "#4b4545",
                                            fontWeight: "500",
                                        }}
                                    >
                                        Enjoy Dubai Escapades
                                    </p>
                                    <p className="xl:text-sm lg:text-sm md:text-xs text-xs font-semibold uppercase"
                                        style={{
                                            color: "#4b4545",
                                            fontWeight: "500",
                                        }}>
                                        Dubai (6N)
                                    </p>

                                </div>
                                <div
                                    className="  xl:text-base lg:text-sm md:text-xs text-sm text-center"
                                    style={{ fontFamily: "Figtree" }}
                                >
                                    {/* <p className='xl:mt-4 lg:mt-4 md:mt-4'>Inclusions</p> */}
                                    <div className="flex items-center justify-start xl:mt-4 lg:mt-4 md:mt-2 mt-4 ">
                                        <div
                                            className=" rounded-full p-2  border"
                                            style={{ background: "#f0f8ff" }}
                                        >
                                            <FaBuilding className="text-blue-700 " size={17} />
                                        </div>
                                        <div
                                            className=" rounded-full p-2 ml-2 border"
                                            style={{ background: "#f0f8ff" }}
                                        >
                                            <FaCar className="text-blue-700 " size={17} />
                                        </div>
                                        <div
                                            className=" rounded-full p-2 ml-2 border"
                                            style={{ background: "#f0f8ff" }}
                                        >
                                            <FaCamera className="text-blue-700 " size={17} />
                                        </div>
                                        <div
                                            className=" rounded-full p-2 ml-2 border"
                                            style={{ background: "#f0f8ff" }}
                                        >
                                            <img src="https://i.ibb.co/ZfGzscB/tickett.png" className="w-4 h-4 text-blue-700" />
                                        </div>
                                        <div
                                            className=" rounded-full p-2 mr-2 ml-2 border"
                                            style={{ background: "#f0f8ff" }}
                                        >
                                            <FaPassport className="text-blue-700 " size={16} />
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className=" xl:text-2xl lg:text-2xl md:text-xl text-2xl"
                                    style={{ fontFamily: "Figtree" }}
                                >
                                    <p
                                        className="mt-5 font-bold"
                                        style={{ fontFamily: "Figtree" }}
                                    >
                                        INR 61,000/-{" "}
                                        <span className="xl:text-sm font-normal">per person</span>
                                    </p>
                                    <p className="xl:text-md lg:text-md md:text-md text-sm line-through">
                                        INR 73,200
                                    </p>

                                </div>

                                <div className="flex justify-between items-center text-sm text-center mt-2">
                                    <div className="xl:w-2/12 w-22/12 xl:pl-0 lg:pl-4">
                                        <div className="border rounded border-blue-700 py-4 px-3">
                                            <a href="tel:08046427999">
                                                <img src={telephone} className="xl:w-4 ml-111 w-4" />
                                            </a>
                                        </div>
                                    </div>
                                    <button
                                        className="ml-2 xl:w-10/12 w-10/12 bg-blue-600 hover:bg-blue-700 text-white py-3 font-semibold text-base px-4 mt-4 xl:mb-4 lg:mb-4 md:mb-4 mb-4 rounded focus:outline-none focus:shadow-outline"
                                        type="button"
                                        onClick={() => {
                                            setShowModal(true);
                                            setFormData({
                                                ...formData,
                                                package_name: "Enjoy Dubai Escapades",
                                            });
                                        }}
                                        style={{ fontFamily: "Figtree" }}
                                    >
                                        Enquiry Now
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* third grid */}

                        <div>
                            <img
                                src={db7}
                                alt="dubai1"
                                className="rounded-t w-full xl:h-52 lg:h-48 md:h-48"
                            />
                            <div className="px-4 pt-4 bg-white xl:pt-2 lg:pt-2 border  md:pt-2 rounded-md">
                                <div
                                    className="  xl:text-xl text-left"
                                    style={{ fontFamily: "Figtree" }}
                                >
                                    <div className="flex">
                                        <p className="mt-2  text-sm text-blue-700 font-semibold uppercase">
                                            6 Nights  7 Days
                                        </p>
                                        <p className="mt-2 text-sm xl:ml-20 lg:ml-48 md:ml-24 ml-32 text-blue-700 font-semibold uppercase ">
                                            <i className="fas fa-star text-yellow-500 mr-1"></i>
                                            4.9 (1462)
                                        </p>
                                    </div>

                                    <p
                                        className="mt-1 xl:text-2xl lg:text-2xl md:text-xl text-2xl"
                                        style={{
                                            color: "#4b4545",
                                            fontWeight: "500",
                                        }}
                                    >
                                        Dubai with Yas Island
                                    </p>
                                    <p className="xl:text-sm lg:text-sm md:text-xs text-xs font-semibold uppercase"
                                        style={{
                                            color: "#4b4545",
                                            fontWeight: "500",
                                        }}>
                                        Dubai (5N) , Yas Island (1N)
                                    </p>


                                </div>

                                <div
                                    className="  xl:text-base lg:text-sm md:text-xs text-sm text-center"
                                    style={{ fontFamily: "Figtree" }}
                                >
                                    {/* <p className='xl:mt-4 lg:mt-4 md:mt-4'>Inclusions</p> */}
                                    <div className="flex items-center justify-start xl:mt-4 lg:mt-4 md:mt-2 mt-4 ">
                                        <div
                                            className=" rounded-full p-2  border"
                                            style={{ background: "#f0f8ff" }}
                                        >
                                            <FaBuilding className="text-blue-700 " size={17} />
                                        </div>
                                        <div
                                            className=" rounded-full p-2 ml-2 border"
                                            style={{ background: "#f0f8ff" }}
                                        >
                                            <FaCar className="text-blue-700 " size={17} />
                                        </div>
                                        <div
                                            className=" rounded-full p-2 ml-2 border"
                                            style={{ background: "#f0f8ff" }}
                                        >
                                            <FaCamera className="text-blue-700 " size={17} />
                                        </div>
                                        <div
                                            className=" rounded-full p-2 ml-2 border"
                                            style={{ background: "#f0f8ff" }}
                                        >
                                            <img src="https://i.ibb.co/ZfGzscB/tickett.png" className="w-4 h-4 text-blue-700" />
                                        </div>
                                        <div
                                            className=" rounded-full p-2 mr-2 ml-2 border"
                                            style={{ background: "#f0f8ff" }}
                                        >
                                            <FaPassport className="text-blue-700 " size={16} />
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className=" xl:text-2xl lg:text-2xl md:text-xl text-left"
                                    style={{ fontFamily: "Figtree" }}
                                >
                                    <p
                                        className="mt-5 font-bold"
                                        style={{ fontFamily: "Figtree" }}
                                    >
                                        INR 78,000/-{" "}
                                        <span className="xl:text-sm font-normal">per person</span>
                                    </p>
                                    <p className="xl:text-md lg:text-md md:text-md text-sm line-through">
                                        INR 93,600
                                    </p>

                                </div>

                                <div className="flex justify-between items-center text-sm text-center mt-2">
                                    <div className="xl:w-2/12 w-22/12 xl:pl-0 lg:pl-4">
                                        <div className="border rounded border-blue-700 py-4 px-3">
                                            <a href="tel:08046427999">
                                                <img src={telephone} className="xl:w-4 ml-111 w-4" />
                                            </a>
                                        </div>
                                    </div>
                                    <button
                                        className="ml-2 xl:w-10/12 w-10/12 bg-blue-600 hover:bg-blue-700 text-white py-3 font-semibold text-base px-4 mt-4 xl:mb-4 lg:mb-4 md:mb-4 mb-4 rounded focus:outline-none focus:shadow-outline"
                                        type="button"
                                        onClick={() => {
                                            setShowModal(true);
                                            setFormData({
                                                ...formData,
                                                package_name: "Tour Dubai Extravaganza",
                                            });
                                        }}
                                        style={{ fontFamily: "Figtree" }}
                                    >
                                        Enquiry Now
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* fourth grid */}

                        <div>
                            <img
                                src={db8}
                                alt="dubai1"
                                className="rounded-t w-full xl:h-52 lg:h-48 md:h-48"
                            />
                            <div className="px-4 pt-4 bg-white xl:pt-2 lg:pt-2 border  md:pt-2 rounded-md">
                                <div
                                    className="  xl:text-xl text-left"
                                    style={{ fontFamily: "Figtree" }}
                                >
                                    <div className="flex">
                                        <p className="mt-2  text-sm text-blue-700 font-semibold uppercase">
                                            7 Nights  8 Days
                                        </p>
                                        <p className="mt-2 text-sm xl:ml-20 lg:ml-48 md:ml-24 ml-36 text-blue-700 font-semibold uppercase ">
                                            <i className="fas fa-star text-yellow-500 mr-1"></i>
                                            4.9 (1163)
                                        </p>
                                    </div>

                                    <p
                                        className="mt-1 xl:text-2xl lg:text-2xl md:text-xl text-2xl"
                                        style={{
                                            color: "#4b4545",
                                            fontWeight: "500",
                                        }}
                                    >
                                        Embrace Dubai Culture
                                    </p>
                                    <p className="xl:text-sm lg:text-sm md:text-xs text-xs font-semibold uppercase"
                                        style={{
                                            color: "#4b4545",
                                            fontWeight: "500",
                                        }}>
                                        Dubai (7N)
                                    </p>

                                </div>

                                <div
                                    className="  xl:text-base lg:text-sm md:text-xs text-sm text-center"
                                    style={{ fontFamily: "Figtree" }}
                                >
                                    {/* <p className='xl:mt-4 lg:mt-4 md:mt-4'>Inclusions</p> */}
                                    <div className="flex items-center justify-start xl:mt-4 lg:mt-4 md:mt-2 mt-4 ">
                                        <div
                                            className=" rounded-full p-2  border"
                                            style={{ background: "#f0f8ff" }}
                                        >
                                            <FaBuilding className="text-blue-700 " size={17} />
                                        </div>
                                        <div
                                            className=" rounded-full p-2 ml-2 border"
                                            style={{ background: "#f0f8ff" }}
                                        >
                                            <FaCar className="text-blue-700 " size={17} />
                                        </div>
                                        <div
                                            className=" rounded-full p-2 ml-2 border"
                                            style={{ background: "#f0f8ff" }}
                                        >
                                            <FaCamera className="text-blue-700 " size={17} />
                                        </div>
                                        <div
                                            className=" rounded-full p-2 ml-2 border"
                                            style={{ background: "#f0f8ff" }}
                                        >
                                            <img src="https://i.ibb.co/ZfGzscB/tickett.png" className="w-4 h-4 text-blue-700" />
                                        </div>
                                        <div
                                            className=" rounded-full p-2 mr-2 ml-2 border"
                                            style={{ background: "#f0f8ff" }}
                                        >
                                            <FaPassport className="text-blue-700 " size={16} />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className=" xl:text-2xl lg:text-2xl md:text-xl text-2xl"
                                    style={{ fontFamily: "Figtree" }}
                                >
                                    <p
                                        className="mt-5 font-bold"
                                        style={{ fontFamily: "Figtree" }}
                                    >
                                        INR 64,000/-{" "}
                                        <span className="xl:text-sm font-normal">per person</span>
                                    </p>
                                    <p className="xl:text-md lg:text-md md:text-md text-sm line-through">
                                        INR 76,800
                                    </p>
                                </div>

                                <div className="flex justify-between items-center text-sm text-center mt-2">
                                    <div className="xl:w-2/12 w-22/12 xl:pl-0 lg:pl-4">
                                        <div className="border rounded border-blue-700 py-4 px-3">
                                            <a href="tel:08046427999">
                                                <img src={telephone} className="xl:w-4 ml-111 w-4" />
                                            </a>
                                        </div>
                                    </div>
                                    <button
                                        className="ml-2 xl:w-10/12 w-10/12 bg-blue-600 hover:bg-blue-700 text-white py-3 font-semibold text-base px-4 mt-4 xl:mb-4 lg:mb-4 md:mb-4 mb-4 rounded focus:outline-none focus:shadow-outline"
                                        type="button"
                                        onClick={() => {
                                            setShowModal(true);
                                            setFormData({
                                                ...formData,
                                                package_name: "Embrace Dubai Culture",
                                            });
                                        }}
                                        style={{ fontFamily: "Figtree" }}
                                    >
                                        Enquiry Now
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* 3rd list */}

            <section className="overflow-hidden xl:block lg:block md:block block container xl:py-0 lg:py-0 md:py-0 py-0 mx-auto pb-12">
                <div className="xl:mt-4 lg:mt-4 md:mt-4 mt-4 xl:px-0 px-2">
                    <div className="grid xl:grid-cols-4 lg:grid-cols-2 md:grid-cols-2 xl:gap-4 lg:gap-2 md:gap-2 gap-4 xl:px-24 lg:px-12 md:px-8 xl:pb-8 lg:pb-8 md:pb-4">
                        {/* first grid */}

                        <div>
                            <img
                                src={db9}
                                alt="dubai1"
                                className="rounded-t w-full xl:h-52 lg:h-48 md:h-48"
                            />
                            <div className="px-4 pt-4 bg-white xl:pt-2 lg:pt-2 border  md:pt-2 rounded-md">
                                <div
                                    className="  xl:text-xl text-left"
                                    style={{ fontFamily: "Figtree" }}
                                >
                                    <div className="flex">
                                        <p className="mt-2  text-sm text-blue-700 font-semibold uppercase">
                                            7 Nights  8 Days
                                        </p>
                                        <p className="mt-2 text-sm xl:ml-20 lg:ml-48 md:ml-24 ml-32 text-blue-700 font-semibold uppercase ">
                                            <i className="fas fa-star text-yellow-500 mr-1"></i>
                                            4.9 (1801)
                                        </p>
                                    </div>

                                    <p
                                        className="mt-1 xl:text-2xl lg:text-2xl md:text-xl text-2xl"
                                        style={{
                                            color: "#4b4545",
                                            fontWeight: "500",
                                        }}
                                    >
                                        Luxurious Dubai Delights
                                    </p>

                                    <p className="xl:text-sm lg:text-sm md:text-xs text-xs font-semibold uppercase"
                                        style={{
                                            color: "#4b4545",
                                            fontWeight: "500",
                                        }}>
                                        Dubai (7N)
                                    </p>

                                </div>

                                <div
                                    className="  xl:text-base lg:text-sm md:text-xs text-sm text-center"
                                    style={{ fontFamily: "Figtree" }}
                                >
                                    {/* <p className='xl:mt-4 lg:mt-4 md:mt-4'>Inclusions</p> */}
                                    <div className="flex items-center justify-start xl:mt-4 lg:mt-4 md:mt-2 mt-4 ">
                                        <div
                                            className=" rounded-full p-2  border"
                                            style={{ background: "#f0f8ff" }}
                                        >
                                            <FaBuilding className="text-blue-700 " size={17} />
                                        </div>
                                        <div
                                            className=" rounded-full p-2 ml-2 border"
                                            style={{ background: "#f0f8ff" }}
                                        >
                                            <FaCar className="text-blue-700 " size={17} />
                                        </div>
                                        <div
                                            className=" rounded-full p-2 ml-2 border"
                                            style={{ background: "#f0f8ff" }}
                                        >
                                            <FaCamera className="text-blue-700 " size={17} />
                                        </div>
                                        <div
                                            className=" rounded-full p-2 ml-2 border"
                                            style={{ background: "#f0f8ff" }}
                                        >
                                            <img src="https://i.ibb.co/ZfGzscB/tickett.png" className="w-4 h-4 text-blue-700" />
                                        </div>
                                        <div
                                            className=" rounded-full p-2 mr-2 ml-2 border"
                                            style={{ background: "#f0f8ff" }}
                                        >
                                            <FaPassport className="text-blue-700 " size={16} />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className=" xl:text-2xl lg:text-2xl md:text-xl text-2xl"
                                    style={{ fontFamily: "Figtree" }}
                                >
                                    <p
                                        className="mt-5 font-bold"
                                        style={{ fontFamily: "Figtree" }}
                                    >
                                        INR 79,000/-{" "}
                                        <span className="xl:text-sm font-normal">per person</span>
                                    </p>
                                    <p className="xl:text-md lg:text-md md:text-md text-sm line-through">
                                        INR 94,800
                                    </p>
                                </div>

                                <div className="flex justify-between items-center text-sm text-center mt-2">
                                    <div className="xl:w-2/12 w-22/12 xl:pl-0 lg:pl-4">
                                        <div className="border rounded border-blue-700 py-4 px-3">
                                            <a href="tel:08046427999">
                                                <img src={telephone} className="xl:w-4 ml-111 w-4" />
                                            </a>
                                        </div>
                                    </div>
                                    <button
                                        className="ml-2 xl:w-10/12 w-10/12 bg-blue-600 hover:bg-blue-700 text-white py-3 font-semibold text-base px-4 mt-4 xl:mb-4 lg:mb-4 md:mb-4 mb-4 rounded focus:outline-none focus:shadow-outline"
                                        type="button"
                                        onClick={() => {
                                            setShowModal(true);
                                            setFormData({
                                                ...formData,
                                                package_name: "Luxurious Dubai Delights",
                                            });
                                        }}
                                        style={{ fontFamily: "Figtree" }}
                                    >
                                        Enquiry Now
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* second grid inter*/}

                        <div>
                            <img
                                src={db10}
                                alt="dubai1"
                                className="rounded-t w-full xl:h-52 lg:h-48 md:h-48"
                            />
                            <div className="px-4 pt-4 bg-white xl:pt-2 lg:pt-2 border md:pt-2  rounded-md">
                                <div
                                    className="  xl:text-xl text-left"
                                    style={{ fontFamily: "Figtree" }}
                                >
                                    <div className="flex">
                                        <p className="mt-2  text-sm text-blue-700 font-semibold uppercase">
                                            6 Nights  7 Days
                                        </p>
                                        <p className="mt-2 text-sm xl:ml-20 lg:ml-48 md:ml-24 ml-32 text-blue-700 font-semibold uppercase ">
                                            <i className="fas fa-star text-yellow-500 mr-1"></i>
                                            4.8 (1923)
                                        </p>
                                    </div>


                                    <p
                                        className="mt-1 xl:text-2xl lg:text-2xl md:text-xl text-2xl"
                                        style={{
                                            color: "#4b4545",
                                            fontWeight: "500",
                                        }}
                                    >
                                        Explore Dubai Grandeur
                                    </p>
                                    <p className="xl:text-sm lg:text-sm md:text-xs text-xs font-semibold uppercase"
                                        style={{
                                            color: "#4b4545",
                                            fontWeight: "500",
                                        }}>
                                        Dubai (6N)
                                    </p>
                                </div>
                                <div
                                    className="  xl:text-base lg:text-sm md:text-xs text-sm text-center"
                                    style={{ fontFamily: "Figtree" }}
                                >
                                    {/* <p className='xl:mt-4 lg:mt-4 md:mt-4'>Inclusions</p> */}
                                    <div className="flex items-center justify-start xl:mt-4 lg:mt-4 md:mt-2 mt-4 ">
                                        <div
                                            className=" rounded-full p-2  border"
                                            style={{ background: "#f0f8ff" }}
                                        >
                                            <FaBuilding className="text-blue-700 " size={17} />
                                        </div>
                                        <div
                                            className=" rounded-full p-2 ml-2 border"
                                            style={{ background: "#f0f8ff" }}
                                        >
                                            <FaCar className="text-blue-700 " size={17} />
                                        </div>
                                        <div
                                            className=" rounded-full p-2 ml-2 border"
                                            style={{ background: "#f0f8ff" }}
                                        >
                                            <FaCamera className="text-blue-700 " size={17} />
                                        </div>
                                        <div
                                            className=" rounded-full p-2 ml-2 border"
                                            style={{ background: "#f0f8ff" }}
                                        >
                                            <img src="https://i.ibb.co/ZfGzscB/tickett.png" className="w-4 h-4 text-blue-700" />
                                        </div>
                                        <div
                                            className=" rounded-full p-2 mr-2 ml-2 border"
                                            style={{ background: "#f0f8ff" }}
                                        >
                                            <FaPassport className="text-blue-700 " size={16} />
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className=" xl:text-2xl lg:text-2xl md:text-xl text-2xl"
                                    style={{ fontFamily: "Figtree" }}
                                >
                                    <p
                                        className="mt-5 font-bold"
                                        style={{ fontFamily: "Figtree" }}
                                    >
                                        INR 68,000/-{" "}
                                        <span className="xl:text-sm font-normal">per person</span>
                                    </p>
                                    <p className="xl:text-md lg:text-md md:text-md text-sm line-through">
                                        INR 81,600
                                    </p>

                                </div>

                                <div className="flex justify-between items-center text-sm text-center mt-2">
                                    <div className="xl:w-2/12 w-22/12 xl:pl-0 lg:pl-4">
                                        <div className="border rounded border-blue-700 py-4 px-3">
                                            <a href="tel:08046427999">
                                                <img src={telephone} className="xl:w-4 ml-111 w-4" />
                                            </a>
                                        </div>
                                    </div>
                                    <button
                                        className="ml-2 xl:w-10/12 w-10/12 bg-blue-600 hover:bg-blue-700 text-white py-3 font-semibold text-base px-4 mt-4 xl:mb-4 lg:mb-4 md:mb-4 mb-4 rounded focus:outline-none focus:shadow-outline"
                                        type="button"
                                        onClick={() => {
                                            setShowModal(true);
                                            setFormData({
                                                ...formData,
                                                package_name: "Explore Dubai Grandeur",
                                            });
                                        }}
                                        style={{ fontFamily: "Figtree" }}
                                    >
                                        Enquiry Now
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* third grid */}

                        <div>
                            <img
                                src={db11}
                                alt="dubai1"
                                className="rounded-t w-full xl:h-52 lg:h-48 md:h-48"
                            />
                            <div className="px-4 pt-4 bg-white xl:pt-2 lg:pt-2 border  md:pt-2 rounded-md">
                                <div
                                    className="  xl:text-xl text-left"
                                    style={{ fontFamily: "Figtree" }}
                                >
                                    <div className="flex">
                                        <p className="mt-2  text-sm text-blue-700 font-semibold uppercase">
                                            7 Nights  8 Days
                                        </p>
                                        <p className="mt-2 text-sm xl:ml-20 lg:ml-48 md:ml-24 ml-32 text-blue-700 font-semibold uppercase ">
                                            <i className="fas fa-star text-yellow-500 mr-1"></i>
                                            4.9 (1946)
                                        </p>
                                    </div>


                                    <p
                                        className="mt-1 xl:text-2xl lg:text-2xl md:text-xl text-2xl"
                                        style={{
                                            color: "#4b4545",
                                            fontWeight: "500",
                                        }}
                                    >
                                        Majestic Dubai Wonders
                                    </p>
                                    <p className="xl:text-sm lg:text-sm md:text-xs text-xs font-semibold uppercase"
                                        style={{
                                            color: "#4b4545",
                                            fontWeight: "500",
                                        }}>
                                        Dubai (7N)
                                    </p>

                                </div>

                                <div
                                    className="  xl:text-base lg:text-sm md:text-xs text-sm text-center"
                                    style={{ fontFamily: "Figtree" }}
                                >
                                    {/* <p className='xl:mt-4 lg:mt-4 md:mt-4'>Inclusions</p> */}
                                    <div className="flex items-center justify-start xl:mt-4 lg:mt-4 md:mt-2 mt-4 ">
                                        <div
                                            className=" rounded-full p-2  border"
                                            style={{ background: "#f0f8ff" }}
                                        >
                                            <FaBuilding className="text-blue-700 " size={17} />
                                        </div>
                                        <div
                                            className=" rounded-full p-2 ml-2 border"
                                            style={{ background: "#f0f8ff" }}
                                        >
                                            <FaCar className="text-blue-700 " size={17} />
                                        </div>
                                        <div
                                            className=" rounded-full p-2 ml-2 border"
                                            style={{ background: "#f0f8ff" }}
                                        >
                                            <FaCamera className="text-blue-700 " size={17} />
                                        </div>
                                        <div
                                            className=" rounded-full p-2 ml-2 border"
                                            style={{ background: "#f0f8ff" }}
                                        >
                                            <img src="https://i.ibb.co/ZfGzscB/tickett.png" className="w-4 h-4 text-blue-700" />
                                        </div>
                                        <div
                                            className=" rounded-full p-2 mr-2 ml-2 border"
                                            style={{ background: "#f0f8ff" }}
                                        >
                                            <FaPassport className="text-blue-700 " size={16} />
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className=" xl:text-2xl lg:text-2xl md:text-xl text-2xl"
                                    style={{ fontFamily: "Figtree" }}
                                >
                                    <p
                                        className="mt-5 font-bold"
                                        style={{ fontFamily: "Figtree" }}
                                    >
                                        INR 81,000/-{" "}
                                        <span className="xl:text-sm font-normal">per person</span>
                                    </p>
                                    <p className="xl:text-md lg:text-md md:text-md text-sm line-through">
                                        INR 97,200
                                    </p>

                                </div>

                                <div className="flex justify-between items-center text-sm text-center mt-2">
                                    <div className="xl:w-2/12 w-22/12 xl:pl-0 lg:pl-4">
                                        <div className="border rounded border-blue-700 py-4 px-3">
                                            <a href="tel:08046427999">
                                                <img src={telephone} className="xl:w-4 ml-111 w-4" />
                                            </a>
                                        </div>
                                    </div>
                                    <button
                                        className="ml-2 xl:w-10/12 w-10/12 bg-blue-600 hover:bg-blue-700 text-white py-3 font-semibold text-base px-4 mt-4 xl:mb-4 lg:mb-4 md:mb-4 mb-4 rounded focus:outline-none focus:shadow-outline"
                                        type="button"
                                        onClick={() => {
                                            setShowModal(true);
                                            setFormData({
                                                ...formData,
                                                package_name: "Majestic Dubai Wonders",
                                            });
                                        }}
                                        style={{ fontFamily: "Figtree" }}
                                    >
                                        Enquiry Now
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* fourth grid */}

                        <div>
                            <img
                                src={db12}
                                alt="dubai1"
                                className="rounded-t w-full xl:h-52 lg:h-48 md:h-48"
                            />
                            <div className="px-4 pt-4 bg-white xl:pt-2 lg:pt-2 border  md:pt-2 rounded-md">
                                <div
                                    className="  xl:text-xl text-left"
                                    style={{ fontFamily: "Figtree" }}
                                >
                                    <div className="flex">
                                        <p className="mt-2  text-sm text-blue-700 font-semibold uppercase">
                                            7 Nights  8 Days
                                        </p>
                                        <p className="mt-2 text-sm xl:ml-20 lg:ml-48 md:ml-24 ml-32 text-blue-700 font-semibold uppercase ">
                                            <i className="fas fa-star text-yellow-500 mr-1"></i>
                                            4.9 (1823)
                                        </p>
                                    </div>

                                    <p
                                        className="mt-1 xl:text-2xl lg:text-2xl md:text-xl text-2xl"
                                        style={{
                                            color: "#4b4545",
                                            fontWeight: "500",
                                        }}
                                    >
                                        Discover Dubai Elegance
                                    </p>
                                    <p className="xl:text-sm lg:text-sm md:text-xs text-xs font-semibold uppercase"
                                        style={{
                                            color: "#4b4545",
                                            fontWeight: "500",
                                        }}>
                                        Dubai (7N)
                                    </p>

                                </div>

                                <div
                                    className="  xl:text-base lg:text-sm md:text-xs text-sm text-center"
                                    style={{ fontFamily: "Figtree" }}
                                >
                                    {/* <p className='xl:mt-4 lg:mt-4 md:mt-4'>Inclusions</p> */}
                                    <div className="flex items-center justify-start xl:mt-4 lg:mt-4 md:mt-2 mt-4 ">
                                        <div
                                            className=" rounded-full p-2  border"
                                            style={{ background: "#f0f8ff" }}
                                        >
                                            <FaBuilding className="text-blue-700 " size={17} />
                                        </div>
                                        <div
                                            className=" rounded-full p-2 ml-2 border"
                                            style={{ background: "#f0f8ff" }}
                                        >
                                            <FaCar className="text-blue-700 " size={17} />
                                        </div>
                                        <div
                                            className=" rounded-full p-2 ml-2 border"
                                            style={{ background: "#f0f8ff" }}
                                        >
                                            <FaCamera className="text-blue-700 " size={17} />
                                        </div>
                                        <div
                                            className=" rounded-full p-2 ml-2 border"
                                            style={{ background: "#f0f8ff" }}
                                        >
                                            <img src="https://i.ibb.co/ZfGzscB/tickett.png" className="w-4 h-4 text-blue-700" />
                                        </div>
                                        <div
                                            className=" rounded-full p-2 mr-2 ml-2 border"
                                            style={{ background: "#f0f8ff" }}
                                        >
                                            <FaPassport className="text-blue-700 " size={16} />
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className=" xl:text-2xl lg:text-2xl md:text-xl text-2xl"
                                    style={{ fontFamily: "Figtree" }}
                                >
                                    <p
                                        className="mt-5 font-bold"
                                        style={{ fontFamily: "Figtree" }}
                                    >
                                        INR 84,000/-{" "}
                                        <span className="xl:text-sm font-normal">per person</span>
                                    </p>
                                    <p className="xl:text-md lg:text-md md:text-md text-sm line-through">
                                        INR 1,00,800
                                    </p>

                                </div>

                                <div className="flex justify-between items-center text-sm text-center mt-2">
                                    <div className="xl:w-2/12 w-22/12 xl:pl-0 lg:pl-4">
                                        <div className="border rounded border-blue-700 py-4 px-3">
                                            <a href="tel:08046427999">
                                                <img src={telephone} className="xl:w-4 ml-111 w-4" />
                                            </a>
                                        </div>
                                    </div>
                                    <button
                                        className="ml-2 xl:w-10/12 w-10/12 bg-blue-600 hover:bg-blue-700 text-white py-3 font-semibold text-base px-4 mt-4 xl:mb-4 lg:mb-4 md:mb-4 mb-4 rounded focus:outline-none focus:shadow-outline"
                                        type="button"
                                        onClick={() => {
                                            setShowModal(true);
                                            setFormData({
                                                ...formData,
                                                package_name: "Discover Dubai Elegance",
                                            });
                                        }}
                                        style={{ fontFamily: "Figtree" }}
                                    >
                                        Enquiry Now
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* mobile view */}

            {/* 
            <section className='overflow-hidden xl:hiden lg:hidden md:hidden block container xl:py-0 lg:py-0 md:py-0 py-0 mx-auto' >
                <div className='xl:mt-4 lg:mt-4 md:mt-4 mt-4 px-4'>
                    <div className='grid xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-4 xl:gap-4 lg:gap-2 md:gap-2 gap-4  xl:px-20 lg:px-12 md:px-8'>

                        {/* first grid 

                        <div>
                            <img src={db1} alt="dubai1" className='rounded-md w-full xl:h-52 lg:h-48 md:h-48' />
                            <div className='bg-white xl:pt-2 lg:pt-2 md:pt-2 pt-2 rounded-md'>
                                <div className='  xl:text-xl text-left' style={{ fontFamily: 'Figtree' }}>
                                    <p className='text-semibold mt-2 xl:text-xl lg:text-lg md:text-base text-xl'>Discover Dubai Delights</p>
                                    <p className='mt-2  text-sm text-blue-700  font-semibold uppercase'>4 Nights  5 Days</p>
                                </div>

                                <div className=' xl:text-2xl lg:text-2xl md:text-xl text-left' style={{ fontFamily: 'Figtree' }}>
                                    <p className='mt-4 font-bold' style={{ fontFamily: 'Figtree' }}>INR 40,000/- <span className="xl:text-sm font-normal">per person</span></p>
                                </div>

                                <div className='  xl:text-base lg:text-sm md:text-xs text-sm text-center' style={{ fontFamily: 'Figtree' }}>
                                    <p className='xl:mt-4 lg:mt-4 md:mt-4'>Inclusions</p>
                                    <div className='flex items-center justify-start xl:mt-4 lg:mt-2  md:mt-2 mt-4 '>
                                        <div className=" rounded-full p-2  border" style={{background: '#f0f8ff'}}>
                                        <FaBuilding className='text-blue-700 ' size={17} />
                                        </div>
                                        <div className=" rounded-full p-2 ml-2 border" style={{background: '#f0f8ff'}}>
                                        <FaCar className='text-blue-700 ' size={17} />
                                        </div>
                                        <div className=" rounded-full p-2 ml-2 border" style={{background: '#f0f8ff'}}>
                                        <FaCamera className='text-blue-700 ' size={17} />
                                        </div>
                                        <div className=" rounded-full p-2 ml-2 border" style={{background: '#f0f8ff'}}>
                                        <FaTicketAlt className='text-blue-700' size={15} />
                                        </div>
                                        <div className=" rounded-full p-2 mr-2 ml-2 border" style={{background: '#f0f8ff'}}>
                                        <FaPassport className='text-blue-700 ' size={16} />
                                        </div>
                                    </div>
                                </div>

                                <div className='  text-sm text-center' style={{ fontFamily: 'Figtree' }}>
                                    <button
                                        className='bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 mt-6 xl:mb-4 lg:mb-4 md:mb-4 mb-4 rounded focus:outline-none focus:shadow-outline'
                                    >
                                        View Details
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* second grid inter


                        <div>
                            <img src={db2} alt="dubai1" className='rounded-md w-full xl:h-52 lg:h-48 md:h-48' />
                            <div className='bg-white xl:pt-2 lg:pt-2 md:pt-2 pt-2 rounded-md'>
                                <div className='  xl:text-xl text-left' style={{ fontFamily: 'Figtree' }}>
                                    <p className='mt-1 xl:text-2xl lg:text-lg md:text-base text-xl' style={{color: '#4b4545',
    fontWeight: '500'}}>Experience Dubai Magic</p>
                                    <p className='mt-2  text-sm text-blue-700  font-semibold uppercase'>5 Nights  6 Days</p>
                                </div>

                                <div className=' xl:text-2xl lg:text-2xl md:text-xl text-left' style={{ fontFamily: 'Figtree' }}>
                                    <p className='mt-4 font-bold' style={{ fontFamily: 'Figtree' }}>INR 53,000/- <span className="xl:text-sm font-normal">per person</span></p>
                                </div>

                                <div className='  xl:text-base lg:text-sm md:text-xs text-sm text-center' style={{ fontFamily: 'Figtree' }}>
                                    <p className='xl:mt-4 lg:mt-4 md:mt-4'>Inclusions</p>
                                    <div className='flex items-center justify-start xl:mt-4 lg:mt-2  md:mt-2 mt-4 '>
                                        <div className=" rounded-full p-2  border" style={{background: '#f0f8ff'}}>
                                        <FaBuilding className='text-blue-700 ' size={17} />
                                        </div>
                                        <div className=" rounded-full p-2 ml-2 border" style={{background: '#f0f8ff'}}>
                                        <FaCar className='text-blue-700 ' size={17} />
                                        </div>
                                        <div className=" rounded-full p-2 ml-2 border" style={{background: '#f0f8ff'}}>
                                        <FaCamera className='text-blue-700 ' size={17} />
                                        </div>
                                        <div className=" rounded-full p-2 ml-2 border" style={{background: '#f0f8ff'}}>
                                        <FaTicketAlt className='text-blue-700' size={15} />
                                        </div>
                                        <div className=" rounded-full p-2 mr-2 ml-2 border" style={{background: '#f0f8ff'}}>
                                        <FaPassport className='text-blue-700 ' size={16} />
                                        </div>
                                    </div>
                                </div>

                                <div className='  text-sm text-center' style={{ fontFamily: 'Figtree' }}>
                                    <button
                                        className='bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 mt-6 xl:mb-4 lg:mb-4 md:mb-4 mb-4 rounded focus:outline-none focus:shadow-outline'
                                    // onClick={handleViewClick}
                                    >
                                        View Details
                                    </button>
                                </div>
                            </div>

                        </div>

                        {/* third grid 


                        <div>
                            <img src={db3} alt="dubai1" className='rounded-md w-full xl:h-52 lg:h-48 md:h-48' />
                            <div className='bg-white xl:pt-2 lg:pt-2 md:pt-2 pt-2 rounded-md'>
                                <div className='  xl:text-xl text-left' style={{ fontFamily: 'Figtree' }}>
                                    <p className='mt-1 xl:text-2xl lg:text-lg md:text-base text-xl' style={{color: '#4b4545',
    fontWeight: '500'}}>Unveil Dubai Wonders</p>
                                    <p className='mt-2  text-sm text-blue-700  font-semibold uppercase'>6 Nights  7 Days</p>
                                </div>

                                <div className=' xl:text-2xl lg:text-2xl md:text-xl text-left' style={{ fontFamily: 'Figtree' }}>
                                    <p className='mt-4 font-bold' style={{ fontFamily: 'Figtree' }}>INR 60,000/- <span className="xl:text-sm font-normal">per person</span></p>
                                </div>

                                <div className='  xl:text-base lg:text-sm md:text-xs text-sm text-center' style={{ fontFamily: 'Figtree' }}>
                                    <p className='xl:mt-4 lg:mt-4 md:mt-4'>Inclusions</p>
                                    <div className='flex items-center justify-start xl:mt-4 lg:mt-2  md:mt-2 mt-4 '>
                                        <div className=" rounded-full p-2  border" style={{background: '#f0f8ff'}}>
                                        <FaBuilding className='text-blue-700 ' size={17} />
                                        </div>
                                        <div className=" rounded-full p-2 ml-2 border" style={{background: '#f0f8ff'}}>
                                        <FaCar className='text-blue-700 ' size={17} />
                                        </div>
                                        <div className=" rounded-full p-2 ml-2 border" style={{background: '#f0f8ff'}}>
                                        <FaCamera className='text-blue-700 ' size={17} />
                                        </div>
                                        <div className=" rounded-full p-2 ml-2 border" style={{background: '#f0f8ff'}}>
                                        <FaTicketAlt className='text-blue-700' size={15} />
                                        </div>
                                        <div className=" rounded-full p-2 mr-2 ml-2 border" style={{background: '#f0f8ff'}}>
                                        <FaPassport className='text-blue-700 ' size={16} />
                                        </div>
                                    </div>
                                </div>

                                <div className='  text-sm text-center' style={{ fontFamily: 'Figtree' }}>
                                    <button
                                        className='bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 mt-6 xl:mb-4 lg:mb-4 md:mb-4 mb-4 rounded focus:outline-none focus:shadow-outline'
                                    // onClick={handleViewClick}
                                    >
                                        View Details
                                    </button>
                                </div>
                            </div>

                        </div>

                        {/* fourth grid 


                        <div>
                            <img src={db4} alt="dubai1" className='rounded-md w-full xl:h-52 lg:h-48 md:h-48' />
                            <div className='bg-white xl:pt-2 lg:pt-2 md:pt-2 pt-2 rounded-md'>
                                <div className='  xl:text-xl text-left' style={{ fontFamily: 'Figtree' }}>
                                    <p className='mt-1 xl:text-2xl lg:text-lg md:text-base text-xl' style={{color: '#4b4545',
    fontWeight: '500'}}>Uncover Dubai Secrets</p>
                                    <p className='mt-2  text-sm text-blue-700  font-semibold uppercase'>5 Nights  6 Days</p>
                                </div>

                                <div className=' xl:text-2xl lg:text-2xl md:text-xl text-left' style={{ fontFamily: 'Figtree' }}>
                                    <p className='mt-4 font-bold' style={{ fontFamily: 'Figtree' }}>INR 83,000/- <span className="xl:text-sm font-normal">per person</span></p>
                                </div>

                                <div className='  xl:text-base lg:text-sm md:text-xs text-sm text-center' style={{ fontFamily: 'Figtree' }}>
                                    <p className='xl:mt-4 lg:mt-4 md:mt-4'>Inclusions</p>
                                    <div className='flex items-center justify-start xl:mt-4 lg:mt-2  md:mt-2 mt-4 '>
                                        <div className=" rounded-full p-2  border" style={{background: '#f0f8ff'}}>
                                        <FaBuilding className='text-blue-700 ' size={17} />
                                        </div>
                                        <div className=" rounded-full p-2 ml-2 border" style={{background: '#f0f8ff'}}>
                                        <FaCar className='text-blue-700 ' size={17} />
                                        </div>
                                        <div className=" rounded-full p-2 ml-2 border" style={{background: '#f0f8ff'}}>
                                        <FaCamera className='text-blue-700 ' size={17} />
                                        </div>
                                        <div className=" rounded-full p-2 ml-2 border" style={{background: '#f0f8ff'}}>
                                        <FaTicketAlt className='text-blue-700' size={15} />
                                        </div>
                                        <div className=" rounded-full p-2 mr-2 ml-2 border" style={{background: '#f0f8ff'}}>
                                        <FaPassport className='text-blue-700 ' size={16} />
                                        </div>
                                    </div>
                                </div>

                                <div className='  text-sm text-center' style={{ fontFamily: 'Figtree' }}>
                                    <button
                                        className='bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 mt-6 xl:mb-4 lg:mb-4 md:mb-4 mb-4 rounded focus:outline-none focus:shadow-outline'
                                    // onClick={handleViewClick}
                                    >
                                        View Details
                                    </button>
                                </div>
                            </div>

                        </div>


                    </div>
                </div>
            </section>


            {/* 2nd list 


            <section className='overflow-hidden xl:hiden lg:hidden md:hidden block container xl:py-0 lg:py-0 md:py-0 py-0 mx-auto' >
                <div className='xl:mt-4 lg:mt-4 md:mt-4 mt-4 px-4'>
                    <div className='grid xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-4 xl:gap-4 lg:gap-2 md:gap-2 gap-4  xl:px-20 lg:px-12 md:px-8'>

                        {/* first grid 


                        <div>
                            <img src={db5} alt="dubai1" className='rounded-md w-full xl:h-52 lg:h-48 md:h-48' />
                            <div className='bg-white xl:pt-2 lg:pt-2 md:pt-2 pt-2 rounded-md'>
                                <div className='  xl:text-xl text-left' style={{ fontFamily: 'Figtree' }}>
                                    <p className='mt-1 xl:text-2xl lg:text-lg md:text-base text-xl' style={{color: '#4b4545',
    fontWeight: '500'}}>Marvel Dubai Attractions</p>
                                    <p className='mt-2  text-sm text-blue-700  font-semibold uppercase'>5 Nights  6 Days</p>
                                </div>

                                <div className=' xl:text-2xl lg:text-2xl md:text-xl text-left' style={{ fontFamily: 'Figtree' }}>
                                    <p className='mt-4 font-bold' style={{ fontFamily: 'Figtree' }}>INR 98,000/- <span className="xl:text-sm font-normal">per person</span></p>
                                </div>

                                <div className='  xl:text-base lg:text-sm md:text-xs text-sm text-center' style={{ fontFamily: 'Figtree' }}>
                                    <p className='xl:mt-4 lg:mt-4 md:mt-4'>Inclusions</p>
                                    <div className='flex items-center justify-start xl:mt-4 lg:mt-2  md:mt-2 mt-4 '>
                                        <div className=" rounded-full p-2  border" style={{background: '#f0f8ff'}}>
                                        <FaBuilding className='text-blue-700 ' size={17} />
                                        </div>
                                        <div className=" rounded-full p-2 ml-2 border" style={{background: '#f0f8ff'}}>
                                        <FaCar className='text-blue-700 ' size={17} />
                                        </div>
                                        <div className=" rounded-full p-2 ml-2 border" style={{background: '#f0f8ff'}}>
                                        <FaCamera className='text-blue-700 ' size={17} />
                                        </div>
                                        <div className=" rounded-full p-2 ml-2 border" style={{background: '#f0f8ff'}}>
                                        <FaTicketAlt className='text-blue-700' size={15} />
                                        </div>
                                        <div className=" rounded-full p-2 mr-2 ml-2 border" style={{background: '#f0f8ff'}}>
                                        <FaPassport className='text-blue-700 ' size={16} />
                                        </div>
                                    </div>
                                </div>

                                <div className='  text-sm text-center' style={{ fontFamily: 'Figtree' }}>
                                    <button
                                        className='bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 mt-6 xl:mb-4 lg:mb-4 md:mb-4 mb-4 rounded focus:outline-none focus:shadow-outline'
                                    // onClick={handleViewClick}
                                    >
                                        View Details
                                    </button>
                                </div>
                            </div>

                        </div>

                        {/* second grid inter


                        <div>
                            <img src={db6} alt="dubai1" className='rounded-md w-full xl:h-52 lg:h-48 md:h-48' />
                            <div className='bg-white xl:pt-2 lg:pt-2 md:pt-2 pt-2 rounded-md'>
                                <div className='  xl:text-xl text-left' style={{ fontFamily: 'Figtree' }}>
                                    <p className='mt-1 xl:text-2xl lg:text-lg md:text-base text-xl' style={{color: '#4b4545',
    fontWeight: '500'}}>Enjoy Dubai Escapades</p>
                                    <p className='mt-2  text-sm text-blue-700  font-semibold uppercase'>6 Nights  7 Days</p>
                                </div>

                                <div className=' xl:text-2xl lg:text-2xl md:text-xl text-left' style={{ fontFamily: 'Figtree' }}>
                                    <p className='mt-4 font-bold' style={{ fontFamily: 'Figtree' }}>INR 1,48,000/- <span className="xl:text-sm font-normal">per person</span></p>
                                </div>

                                <div className='  xl:text-base lg:text-sm md:text-xs text-sm text-center' style={{ fontFamily: 'Figtree' }}>
                                    <p className='xl:mt-4 lg:mt-4 md:mt-4'>Inclusions</p>
                                    <div className='flex items-center justify-start xl:mt-4 lg:mt-2  md:mt-2 mt-4 '>
                                        <div className=" rounded-full p-2  border" style={{background: '#f0f8ff'}}>
                                        <FaBuilding className='text-blue-700 ' size={17} />
                                        </div>
                                        <div className=" rounded-full p-2 ml-2 border" style={{background: '#f0f8ff'}}>
                                        <FaCar className='text-blue-700 ' size={17} />
                                        </div>
                                        <div className=" rounded-full p-2 ml-2 border" style={{background: '#f0f8ff'}}>
                                        <FaCamera className='text-blue-700 ' size={17} />
                                        </div>
                                        <div className=" rounded-full p-2 ml-2 border" style={{background: '#f0f8ff'}}>
                                        <FaTicketAlt className='text-blue-700' size={15} />
                                        </div>
                                        <div className=" rounded-full p-2 mr-2 ml-2 border" style={{background: '#f0f8ff'}}>
                                        <FaPassport className='text-blue-700 ' size={16} />
                                        </div>
                                    </div>
                                </div>

                                <div className='  text-sm text-center' style={{ fontFamily: 'Figtree' }}>
                                    <button
                                        className='bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 mt-6 xl:mb-4 lg:mb-4 md:mb-4 mb-4 rounded focus:outline-none focus:shadow-outline'
                                    // onClick={handleViewClick}
                                    >
                                        View Details
                                    </button>
                                </div>
                            </div>

                        </div>

                        {/* third grid 


                        <div>
                            <img src={db7} alt="dubai1" className='rounded-md w-full xl:h-52 lg:h-48 md:h-48' />
                            <div className='bg-white xl:pt-2 lg:pt-2 md:pt-2 pt-2 rounded-md'>
                                <div className='  xl:text-xl text-left' style={{ fontFamily: 'Figtree' }}>
                                    <p className='mt-1 xl:text-2xl lg:text-lg md:text-base text-xl' style={{color: '#4b4545',
    fontWeight: '500'}}>Tour Dubai Extravaganza</p>
                                    <p className='mt-2  text-sm text-blue-700  font-semibold uppercase'>6 Nights  7 Days</p>
                                </div>

                                <div className=' xl:text-2xl lg:text-2xl md:text-xl text-left' style={{ fontFamily: 'Figtree' }}>
                                    <p className='mt-4 font-bold' style={{ fontFamily: 'Figtree' }}>INR 1,62,000/- <span className="xl:text-sm font-normal">per person</span></p>
                                </div>

                                <div className='  xl:text-base lg:text-sm md:text-xs text-sm text-center' style={{ fontFamily: 'Figtree' }}>
                                    <p className='xl:mt-4 lg:mt-4 md:mt-4'>Inclusions</p>
                                    <div className='flex items-center justify-start xl:mt-4 lg:mt-2  md:mt-2 mt-4 '>
                                        <div className=" rounded-full p-2  border" style={{background: '#f0f8ff'}}>
                                        <FaBuilding className='text-blue-700 ' size={17} />
                                        </div>
                                        <div className=" rounded-full p-2 ml-2 border" style={{background: '#f0f8ff'}}>
                                        <FaCar className='text-blue-700 ' size={17} />
                                        </div>
                                        <div className=" rounded-full p-2 ml-2 border" style={{background: '#f0f8ff'}}>
                                        <FaCamera className='text-blue-700 ' size={17} />
                                        </div>
                                        <div className=" rounded-full p-2 ml-2 border" style={{background: '#f0f8ff'}}>
                                        <FaTicketAlt className='text-blue-700' size={15} />
                                        </div>
                                        <div className=" rounded-full p-2 mr-2 ml-2 border" style={{background: '#f0f8ff'}}>
                                        <FaPassport className='text-blue-700 ' size={16} />
                                        </div>
                                    </div>
                                </div>

                                <div className='  text-sm text-center' style={{ fontFamily: 'Figtree' }}>
                                    <button
                                        className='bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 mt-6 xl:mb-4 lg:mb-4 md:mb-4 mb-4 rounded focus:outline-none focus:shadow-outline'
                                    // onClick={handleViewClick}
                                    >
                                        View Details
                                    </button>
                                </div>
                            </div>

                        </div>

                        {/* fourth grid 


                        <div>
                            <img src={db8} alt="dubai1" className='rounded-md w-full xl:h-52 lg:h-48 md:h-48' />
                            <div className='bg-white xl:pt-2 lg:pt-2 md:pt-2 pt-2 rounded-md'>
                                <div className='  xl:text-xl text-left' style={{ fontFamily: 'Figtree' }}>
                                    <p className='mt-1 xl:text-2xl lg:text-lg md:text-base text-xl' style={{color: '#4b4545',
    fontWeight: '500'}}>Embrace Dubai Culture</p>
                                    <p className='mt-2  text-sm text-blue-700  font-semibold uppercase'>7 Nights  8 Days</p>
                                </div>

                                <div className=' xl:text-2xl lg:text-2xl md:text-xl text-left' style={{ fontFamily: 'Figtree' }}>
                                    <p className='mt-4 font-bold' style={{ fontFamily: 'Figtree' }}>INR 1,46,000/- <span className="xl:text-sm font-normal">per person</span></p>
                                </div>

                                <div className='  xl:text-base lg:text-sm md:text-xs text-sm text-center' style={{ fontFamily: 'Figtree' }}>
                                    <p className='xl:mt-4 lg:mt-4 md:mt-4'>Inclusions</p>
                                    <div className='flex items-center justify-start xl:mt-4 lg:mt-2  md:mt-2 mt-4 '>
                                        <div className=" rounded-full p-2  border" style={{background: '#f0f8ff'}}>
                                        <FaBuilding className='text-blue-700 ' size={17} />
                                        </div>
                                        <div className=" rounded-full p-2 ml-2 border" style={{background: '#f0f8ff'}}>
                                        <FaCar className='text-blue-700 ' size={17} />
                                        </div>
                                        <div className=" rounded-full p-2 ml-2 border" style={{background: '#f0f8ff'}}>
                                        <FaCamera className='text-blue-700 ' size={17} />
                                        </div>
                                        <div className=" rounded-full p-2 ml-2 border" style={{background: '#f0f8ff'}}>
                                        <FaTicketAlt className='text-blue-700' size={15} />
                                        </div>
                                        <div className=" rounded-full p-2 mr-2 ml-2 border" style={{background: '#f0f8ff'}}>
                                        <FaPassport className='text-blue-700 ' size={16} />
                                        </div>
                                    </div>
                                </div>

                                <div className='  text-sm text-center' style={{ fontFamily: 'Figtree' }}>
                                    <button
                                        className='bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 mt-6 xl:mb-4 lg:mb-4 md:mb-4 mb-4 rounded focus:outline-none focus:shadow-outline'
                                    // onClick={handleViewClick}
                                    >
                                        View Details
                                    </button>
                                </div>
                            </div>

                        </div>


                    </div>
                </div>
            </section>




            {/* 3rd list 


            <section className='overflow-hidden xl:hiden lg:hidden md:hidden block container xl:py-0 lg:py-0 md:py-0 py-0 mx-auto ' >
                <div className='xl:mt-4 lg:mt-4 md:mt-4 mt-4 px-4'>
                    <div className='grid xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-4 xl:gap-4 lg:gap-2 md:gap-2 gap-4  xl:px-20 lg:px-12 md:px-8'>

                        {/* first grid 

                        <div>
                            <img src={db9} alt="dubai1" className='rounded-md w-full xl:h-52 lg:h-48 md:h-48' />
                            <div className='bg-white xl:pt-2 lg:pt-2 md:pt-2 pt-2 rounded-md'>
                                <div className='  xl:text-xl text-left' style={{ fontFamily: 'Figtree' }}>
                                    <p className='mt-1 xl:text-2xl lg:text-lg md:text-base text-xl' style={{color: '#4b4545',
    fontWeight: '500'}}>Luxurious Dubai Delights</p>
                                    <p className='mt-2  text-sm text-blue-700  font-semibold uppercase'>7 Nights  8 Days</p>
                                </div>

                                <div className=' xl:text-2xl lg:text-2xl md:text-xl text-left' style={{ fontFamily: 'Figtree' }}>
                                    <p className='mt-4 font-bold' style={{ fontFamily: 'Figtree' }}>INR 1,74,000/- <span className="xl:text-sm font-normal">per person</span></p>
                                </div>

                                <div className='  xl:text-base lg:text-sm md:text-xs text-sm text-center' style={{ fontFamily: 'Figtree' }}>
                                    <p className='xl:mt-4 lg:mt-4 md:mt-4'>Inclusions</p>
                                    <div className='flex items-center justify-start xl:mt-4 lg:mt-2  md:mt-2 mt-4 '>
                                        <div className=" rounded-full p-2  border" style={{background: '#f0f8ff'}}>
                                        <FaBuilding className='text-blue-700 ' size={17} />
                                        </div>
                                        <div className=" rounded-full p-2 ml-2 border" style={{background: '#f0f8ff'}}>
                                        <FaCar className='text-blue-700 ' size={17} />
                                        </div>
                                        <div className=" rounded-full p-2 ml-2 border" style={{background: '#f0f8ff'}}>
                                        <FaCamera className='text-blue-700 ' size={17} />
                                        </div>
                                        <div className=" rounded-full p-2 ml-2 border" style={{background: '#f0f8ff'}}>
                                        <FaTicketAlt className='text-blue-700' size={15} />
                                        </div>
                                        <div className=" rounded-full p-2 mr-2 ml-2 border" style={{background: '#f0f8ff'}}>
                                        <FaPassport className='text-blue-700 ' size={16} />
                                        </div>
                                    </div>
                                </div>

                                <div className='  text-sm text-center' style={{ fontFamily: 'Figtree' }}>
                                    <button
                                        className='bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 mt-6 xl:mb-4 lg:mb-4 md:mb-4 mb-4 rounded focus:outline-none focus:shadow-outline'
                                    // onClick={handleViewClick}
                                    >
                                        View Details
                                    </button>
                                </div>
                            </div>

                        </div>

                        {/* second grid inter


                        <div>
                            <img src={db10} alt="dubai1" className='rounded-md w-full xl:h-52 lg:h-48 md:h-48' />
                            <div className='bg-white xl:pt-2 lg:pt-2 md:pt-2 pt-2 rounded-md'>
                                <div className='  xl:text-xl text-left' style={{ fontFamily: 'Figtree' }}>
                                    <p className='mt-1 xl:text-2xl lg:text-lg md:text-base text-xl' style={{color: '#4b4545',
    fontWeight: '500'}}>Explore Dubai Grandeur</p>
                                    <p className='mt-2  text-sm text-blue-700  font-semibold uppercase'>6 Nights  7 Days</p>
                                </div>

                                <div className=' xl:text-2xl lg:text-2xl md:text-xl text-left' style={{ fontFamily: 'Figtree' }}>
                                    <p className='mt-4 font-bold' style={{ fontFamily: 'Figtree' }}>INR 1,54,000/- <span className="xl:text-sm font-normal">per person</span></p>
                                </div>

                                <div className='  xl:text-base lg:text-sm md:text-xs text-sm text-center' style={{ fontFamily: 'Figtree' }}>
                                    <p className='xl:mt-4 lg:mt-4 md:mt-4'>Inclusions</p>
                                    <div className='flex items-center justify-start xl:mt-4 lg:mt-2  md:mt-2 mt-4 '>
                                        <div className=" rounded-full p-2  border" style={{background: '#f0f8ff'}}>
                                        <FaBuilding className='text-blue-700 ' size={17} />
                                        </div>
                                        <div className=" rounded-full p-2 ml-2 border" style={{background: '#f0f8ff'}}>
                                        <FaCar className='text-blue-700 ' size={17} />
                                        </div>
                                        <div className=" rounded-full p-2 ml-2 border" style={{background: '#f0f8ff'}}>
                                        <FaCamera className='text-blue-700 ' size={17} />
                                        </div>
                                        <div className=" rounded-full p-2 ml-2 border" style={{background: '#f0f8ff'}}>
                                        <FaTicketAlt className='text-blue-700' size={15} />
                                        </div>
                                        <div className=" rounded-full p-2 mr-2 ml-2 border" style={{background: '#f0f8ff'}}>
                                        <FaPassport className='text-blue-700 ' size={16} />
                                        </div>
                                    </div>
                                </div>

                                <div className='  text-sm text-center' style={{ fontFamily: 'Figtree' }}>
                                    <button
                                        className='bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 mt-6 xl:mb-4 lg:mb-4 md:mb-4 mb-4 rounded focus:outline-none focus:shadow-outline'
                                    // onClick={handleViewClick}
                                    >
                                        View Details
                                    </button>
                                </div>
                            </div>

                        </div>

                        {/* third grid 


                        <div>
                            <img src={db11} alt="dubai1" className='rounded-md w-full xl:h-52 lg:h-48 md:h-48' />
                            <div className='bg-white xl:pt-2 lg:pt-2 md:pt-2 pt-2 rounded-md'>
                                <div className='  xl:text-xl text-left' style={{ fontFamily: 'Figtree' }}>
                                    <p className='mt-1 xl:text-2xl lg:text-lg md:text-base text-xl' style={{color: '#4b4545',
    fontWeight: '500'}}>Majestic Dubai Wonders</p>
                                    <p className='mt-2  text-sm text-blue-700  font-semibold uppercase'>7 Nights  8 Days</p>
                                </div>

                                <div className=' xl:text-2xl lg:text-2xl md:text-xl text-left' style={{ fontFamily: 'Figtree' }}>
                                    <p className='mt-4 font-bold' style={{ fontFamily: 'Figtree' }}>INR 1,85,000/- <span className="xl:text-sm font-normal">per person</span></p>
                                </div>

                                <div className='  xl:text-base lg:text-sm md:text-xs text-sm text-center' style={{ fontFamily: 'Figtree' }}>
                                    <p className='xl:mt-4 lg:mt-4 md:mt-4'>Inclusions</p>
                                    <div className='flex items-center justify-start xl:mt-4 lg:mt-2  md:mt-2 mt-4 '>
                                        <div className=" rounded-full p-2  border" style={{background: '#f0f8ff'}}>
                                        <FaBuilding className='text-blue-700 ' size={17} />
                                        </div>
                                        <div className=" rounded-full p-2 ml-2 border" style={{background: '#f0f8ff'}}>
                                        <FaCar className='text-blue-700 ' size={17} />
                                        </div>
                                        <div className=" rounded-full p-2 ml-2 border" style={{background: '#f0f8ff'}}>
                                        <FaCamera className='text-blue-700 ' size={17} />
                                        </div>
                                        <div className=" rounded-full p-2 ml-2 border" style={{background: '#f0f8ff'}}>
                                        <FaTicketAlt className='text-blue-700' size={15} />
                                        </div>
                                        <div className=" rounded-full p-2 mr-2 ml-2 border" style={{background: '#f0f8ff'}}>
                                        <FaPassport className='text-blue-700 ' size={16} />
                                        </div>
                                    </div>
                                </div>

                                <div className='  text-sm text-center' style={{ fontFamily: 'Figtree' }}>
                                    <button
                                        className='bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 mt-6 xl:mb-4 lg:mb-4 md:mb-4 mb-4 rounded focus:outline-none focus:shadow-outline'
                                    // onClick={handleViewClick}
                                    >
                                        View Details
                                    </button>
                                </div>
                            </div>

                        </div>

                        {/* fourth grid


                        <div>
                            <img src={db12} alt="dubai1" className='rounded-md w-full xl:h-52 lg:h-48 md:h-48' />
                            <div className='bg-white xl:pt-2 lg:pt-2 md:pt-2 pt-2 rounded-md'>
                                <div className='  xl:text-xl text-left' style={{ fontFamily: 'Figtree' }}>
                                    <p className='mt-1 xl:text-2xl lg:text-lg md:text-base text-xl' style={{color: '#4b4545',
    fontWeight: '500'}}>Discover Dubai Elegance</p>
                                    <p className='mt-2  text-sm text-blue-700  font-semibold uppercase'>7 Nights  8 Days</p>
                                </div>

                                <div className=' xl:text-2xl lg:text-2xl md:text-xl text-left' style={{ fontFamily: 'Figtree' }}>
                                    <p className='mt-4 font-bold' style={{ fontFamily: 'Figtree' }}>INR 1,96,000/- <span className="xl:text-sm font-normal">per person</span></p>
                                </div>

                                <div className='  xl:text-base lg:text-sm md:text-xs text-sm text-center' style={{ fontFamily: 'Figtree' }}>
                                    <p className='xl:mt-4 lg:mt-4 md:mt-4'>Inclusions</p>
                                    <div className='flex items-center justify-start xl:mt-4 lg:mt-2  md:mt-2 mt-4 '>
                                        <div className=" rounded-full p-2  border" style={{background: '#f0f8ff'}}>
                                        <FaBuilding className='text-blue-700 ' size={17} />
                                        </div>
                                        <div className=" rounded-full p-2 ml-2 border" style={{background: '#f0f8ff'}}>
                                        <FaCar className='text-blue-700 ' size={17} />
                                        </div>
                                        <div className=" rounded-full p-2 ml-2 border" style={{background: '#f0f8ff'}}>
                                        <FaCamera className='text-blue-700 ' size={17} />
                                        </div>
                                        <div className=" rounded-full p-2 ml-2 border" style={{background: '#f0f8ff'}}>
                                        <FaTicketAlt className='text-blue-700' size={15} />
                                        </div>
                                        <div className=" rounded-full p-2 mr-2 ml-2 border" style={{background: '#f0f8ff'}}>
                                        <FaPassport className='text-blue-700 ' size={16} />
                                        </div>
                                    </div>
                                </div>

                                <div className='  text-sm text-center' style={{ fontFamily: 'Figtree' }}>
                                    <button
                                        className='bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 mt-6 xl:mb-4 lg:mb-4 md:mb-4 mb-4 rounded focus:outline-none focus:shadow-outline'
                                    // onClick={handleViewClic
                                    >
                                        View Details
                                    </button>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </section> */}

            {/* testimonials */}

            {/* desktop view  */}

            <section className="bg-white ">
                <div className="overflow-hidden xl:block lg:block md:block hidden container xl:py-2 lg:py-2 w-full md:py-2 py-2 mx-auto">
                    <div className="flex justify-center items-center xl:mt-8 lg:mt-8 md:mt-8 mt-4">
                        <h1 className="">
                            <p
                                style={{ fontFamily: "Figtree" }}
                                className="flex justify-center items-center uppercase xl:text-4xl lg:text-3xl md:text-2xl text-xl font-serif font-bold xl:pl-4 lg:pl-2 md:pl-2 pl-8"
                            >
                                Tour Reviews
                            </p>
                            <p
                                style={{ fontFamily: "Figtree" }}
                                className="flex justify-center items-center xl:text-xl lg:text-lg md:text-md font-serif xl:mt-1 lg:mt-1 md:mt-1 mt-1 xl:pl-4 md:pl-1 pl-1"
                            >
                                Explore Our Newest Dubai Tour Reviews
                            </p>
                        </h1>
                    </div>
                </div>
            </section>

            {/* mobile view  */}

            <section className="bg-white overflow-hidden xl:hidden lg:hidden md:hidden block container xl:py-2 lg:py-2 md:py-2 py-2 w-full">
                <div className="flex justify-center items-center">
                    <h1 className="font-serif font-bold ">
                        <p
                            style={{ fontFamily: "Figtree" }}
                            className="flex justify-center items-center xl:text-4xl lg:text-3xl uppercase md:text-2xl text-2xl font-serif font-bold xl:pl-4 lg:pl-2 md:pl-2 pl-0"
                        >
                            Tour Reviews
                        </p>
                        <p
                            style={{ fontFamily: "Figtree" }}
                            className="flex justify-center items-center xl:text-xl lg:text-lg md:text-md text-sm font-serif font-semibold  xl:mt-1 lg:mt-1 md:mt-1 mt-1 xl:pl-4 md:pl-1 pl-1"
                        >
                            Explore Our Newest Dubai Tour Reviews
                        </p>
                    </h1>
                </div>
            </section>

            {/* desktop view  */}

            {/* part one */}

            <div className="bg-white xl:pb-12 md:pb-12 pb-0">
                <section className="overflow-hidden xl:block lg:block md:block hidden container mx-auto">
                    <div className="xl:mt-4 lg:mt-4 md:mt-4 mt-2">
                        <div
                            className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 grid-cols-1 xl:gap-2 lg:gap-2 md:gap-2 gap-6 
                    xl:px-24 lg:px-2 md:px-8 px-2"
                        >
                            <div className="flex flex-col items-center">
                                {/* 1st column */}

                                <div
                                    className="height xl:pt-2 lg:pt-2 md:pt-2 pt-2 xl:h-550 lg:h-550  rounded-md mt-4 w-full flex flex-col items-center justify-between"
                                    style={{ background: "#eaf0f8" }}
                                >
                                    <img
                                        src={testi11}
                                        alt="dubai1"
                                        className="mt-2 rounded-md xl:w-60 lg:w-60 md:w-44 xl:h-60 lg:h-60 md:h-44 mx-auto "
                                    />
                                    <div className="font-semibold xl:text-xl lg:text-sm md:text-xs text-sm text-center mt-4">
                                        <p
                                            className=" font-semibold mb-4"
                                            style={{ fontFamily: "Figtree" }}
                                        >
                                            Mr. Venkannachar Krishna Murthy Rotti
                                        </p>
                                        <p
                                            style={{
                                                fontFamily: "Figtree",
                                                borderTop: "1px dashed #ccc",
                                            }}
                                            className="xl:py-3 lg:py-3 md:py-3 py-2 xl:text-base lg:text-base md:text-xs text-sm font-normal xl:px-2 mx-4 lg:px-4 md:px-2 px-2 xl:pb-2 lg:pb-0"
                                        >
                                            The warmth and seamless arrangement by The Trawelmart
                                            India and Dubai staff expertly planned every detail of the
                                            trip, and it was exceptionally well-managed! Our journey
                                            with Trawelmart is undoubtedly the best and also the start
                                            of many, many more to come! Much obliged!

                                        </p>
                                    </div>
                                    <div
                                        className="xl:py-4 lg:py-3 md:py-3 py-2 mt-2 w-full"
                                        style={{ background: "#c4daf8", }}
                                    >
                                        <p className="flex justify-center">
                                            {stars.map((_, index) => (
                                                <FaStar key={index} className="text-yellow-500 mx-1" />
                                            ))}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            {/* 2nd column */}
                            <div className="flex flex-col items-center">
                                <div
                                    className="height xl:pt-2 lg:pt-2 md:pt-2 pt-2 xl:h-550 lg:h-550  rounded-md mt-4 w-full flex flex-col items-center justify-between"
                                    style={{ background: "#eaf0f8" }}
                                >
                                    <img
                                        src={t2}
                                        alt="dubai1"
                                        className="mt-2 rounded-md xl:w-60 lg:w-60 md:w-44 xl:h-60 lg:h-60 md:h-44 mx-auto "
                                    />
                                    <div className="font-semibold xl:text-xl lg:text-sm md:text-sm text-sm text-center mt-4">
                                        <p
                                            className=" font-semibold mb-4"
                                            style={{ fontFamily: "Figtree" }}
                                        >
                                            Mr. Vishwanath & Ms. Bhagya
                                        </p>
                                        <p
                                            style={{
                                                fontFamily: "Figtree",
                                                borderTop: "1px dashed #ccc",
                                            }}
                                            className="xl:py-3 lg:py-3 md:py-3 py-2 xl:text-base lg:text-base md:text-xs text-sm font-normal xl:px-2 mx-4 lg:px-4 md:px-2 px-2 xl:pb-2 lg:pb-0"
                                        >
                                            I can summarise my recent vacation to Dubai with Travel
                                            Mart in one word: AMAZING! It was my first time booking a
                                            package vacation. I was quite happy with my choice. The
                                            group's timing was impeccable throughout the trip, the
                                            planning was excellent. With special gratitude to
                                            Trawelmart.
                                        </p>
                                    </div>
                                    <div
                                        className="xl:py-4 lg:py-3 md:py-3 py-2 mt-2 w-full"
                                        style={{ background: "#c4daf8", }}
                                    >
                                        <p className="flex justify-center">
                                            {stars.map((_, index) => (
                                                <FaStar key={index} className="text-yellow-500 mx-1" />
                                            ))}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            {/* 3rd column */}
                            <div className="flex flex-col items-center">
                                <div
                                    className="height xl:pt-2 lg:pt-2 md:pt-2 pt-2 xl:h-550 lg:h-550  rounded-md mt-4 w-full flex flex-col items-center justify-between"
                                    style={{ background: "#eaf0f8" }}
                                >
                                    <img
                                        src={t7}
                                        alt="dubai1"
                                        className="mt-2 rounded-md xl:w-60 lg:w-60 md:w-44 xl:h-60 lg:h-60 md:h-44 mx-auto"
                                    />
                                    <div className="marg-top font-semibold xl:text-xl lg:text-sm md:text-sm text-sm text-center">
                                        <p
                                            className="xl:mt-4 lg:mt-4 md:mt-1 mt-4 font-semibold mb-4"
                                            style={{ fontFamily: "Figtree" }}
                                        >
                                            Ms. Pooja Brahmadev
                                        </p>
                                        <p
                                            style={{
                                                fontFamily: "Figtree",
                                                borderTop: "1px dashed #ccc",
                                            }}
                                            className="xl:px-2 mx-4 lg:px-4 md:px-2 px-2 xl:pb-2 lg:pb-14 xl:py-3 lg:py-3 md:py-3 py-2 xl:text-base lg:text-base md:text-xs text-sm font-normal"
                                        >
                                            Thank you to the entire Trawelmart team and the team in
                                            Dubai. I really enjoyed the services provided by your team
                                            and I'm looking forward to much more travelling and making much
                                            more memories with Trawelmart.{" "}
                                        </p>
                                    </div>
                                    <div
                                        className="xl:py-4 lg:py-3 md:py-3 py-2 mt-2 w-full xl:mt-14 lg:mt-0"
                                        style={{ background: "#c4daf8" }}
                                    >
                                        <p className="flex justify-center">
                                            {stars.map((_, index) => (
                                                <FaStar key={index} className="text-yellow-500 mx-1" />
                                            ))}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* part two */}

                <section className="overflow-hidden xl:block lg:block md:block hidden container xl:py-0 lg:py-0 md:py-0 py-0 mx-auto">
                    <div className="xl:mt-0 lg:mt-0 md:mt-0 mt-2">
                        <div
                            className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 grid-cols-3 xl:gap-2 lg:gap-2 md:gap-2 gap-2 
                    xl:px-24 lg:px-2 md:px-8 px-2"
                        >
                            <div className="flex flex-col items-center">
                                {/* 1st column */}
                                <div
                                    className="heigh bg-white xl:pt-2 lg:pt-2 md:pt-2 pt-2 xl:h-550 lg:h-550  rounded-md mt-4 w-full flex flex-col items-center justify-between"
                                    style={{ background: "#eaf0f8" }}
                                >
                                    <img
                                        src={t3}
                                        alt="dubai1"
                                        className="mt-2 rounded-md xl:w-60 lg:w-60 md:w-44 xl:h-60 lg:h-60 md:h-44 mx-auto"
                                    />
                                    <div className="font-semibold xl:text-xl lg:text-sm md:text-sm text-sm text-center mt-4">
                                        <p
                                            className=" font-semibold mb-4"
                                            style={{ fontFamily: "Figtree" }}
                                        >
                                            Mr. Abhilash & Ms. Indhumathy{" "}
                                        </p>
                                        <hr
                                            className="mx-4"
                                            style={{ border: "1px dashed #ccc" }}
                                        ></hr>
                                        <p
                                            style={{ fontFamily: "Figtree" }}
                                            className="xl:py-3 py-2 xl:text-base lg:text-base md:text-xs text-sm font-normal xl:px-6 lg:px-4 md:px-2 px-2 xl:pb-2 lg:pb-14 xl:mb-8"
                                        >
                                            It was an exhilarating trip to Dubai. We had wonderful
                                            experiences through out. Many thanks to Trawel Mart. We
                                            visited most of the tourist places in Dubai and Abu Dhabi.
                                            Kudos to operations team, coordinator and guides; they
                                            took care of us through out the trip. Highly recommend
                                            TrawelMart for holiday planning.
                                        </p>
                                    </div>
                                    <div
                                        className="xl:py-4 lg:py-3 md:py-3 py-2 mt-2 w-full"
                                        style={{ background: "#c4daf8" }}
                                    >
                                        <p className="flex justify-center">
                                            {stars.map((_, index) => (
                                                <FaStar key={index} className="text-yellow-500 mx-1" />
                                            ))}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            {/* 2nd column */}
                            <div className="flex flex-col items-center">
                                <div
                                    className="heigh bg-white xl:pt-2 lg:pt-2 md:pt-2 pt-2 xl:h-550 lg:h-550  rounded-md mt-4 w-full flex flex-col items-center justify-between"
                                    style={{ background: "#eaf0f8" }}
                                >
                                    <img
                                        src={t4}
                                        alt="dubai1"
                                        className="mt-2 rounded-md xl:w-60 lg:w-60 md:w-44 xl:h-60 lg:h-60 md:h-44 mx-auto"
                                    />
                                    <div className="font-semibold xl:text-xl lg:text-sm md:text-sm text-sm text-center mt-4">
                                        <p
                                            className=" font-semibold mb-4"
                                            style={{ fontFamily: "Figtree" }}
                                        >
                                            Mr. Jeevan & Ms. Harshitha{" "}
                                        </p>
                                        <hr
                                            className="mx-4"
                                            style={{ border: "1px dashed #ccc" }}
                                        ></hr>
                                        <p
                                            style={{ fontFamily: "Figtree" }}
                                            className="xl:py-3 py-2 xl:text-base lg:text-base md:text-xs text-sm font-normal xl:px-6 lg:px-4 md:px-2 px-2 xl:pb-2 lg:pb-20 xl:mb-8"
                                        >
                                            Our trip to Dubai with Trawelmart was soo amazing. Their
                                            attention to detail and personalized service made my trip
                                            unforgettable. From luxurious accommodations to seamless
                                            travel arrangements, every aspect was handled with
                                            professionalism. Looking forward to many more tours with
                                            Trawelmart.{" "}
                                        </p>
                                    </div>
                                    <div
                                        className="xl:py-4 lg:py-3 md:py-3 py-2 mt-2 w-full"
                                        style={{ background: "#c4daf8" }}
                                    >
                                        <p className="flex justify-center">
                                            {stars.map((_, index) => (
                                                <FaStar key={index} className="text-yellow-500 mx-1" />
                                            ))}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            {/* 3rd column */}
                            <div className="flex flex-col items-center">
                                <div
                                    className="heigh bg-white xl:pt-2 lg:pt-2 md:pt-2 pt-2 xl:h-550 lg:h-550 rounded-md mt-4 w-full flex flex-col items-center justify-between"
                                    style={{ background: "#eaf0f8" }}
                                >
                                    <img
                                        src={t8}
                                        alt="dubai1"
                                        className="mt-2 rounded-md xl:w-60 lg:w-60 md:w-44 xl:h-60 lg:h-60 md:h-44 mx-auto"
                                    />
                                    <div className="font-semibold xl:text-xl lg:text-sm md:text-sm text-sm text-center xl:mt-6 lg:mt-4 md:mt-2 mt-4">
                                        <p
                                            className="font-semibold xl:mb-2 lg:mb-4 md:mb-4 mb-4"
                                            style={{ fontFamily: "Figtree" }}
                                        >
                                            Mr. Rohan & Family
                                        </p>
                                        <hr
                                            className="mx-4"
                                            style={{ border: "1px dashed #ccc" }}
                                        ></hr>
                                        <p
                                            style={{ fontFamily: "Figtree" }}
                                            className="mt-2  xl:text-base lg:text-base md:text-xs text-sm font-normal xl:px-4 lg:px-4 md:px-2 px-2 xl:pb-2 lg:pb-14 xl:mb-8"
                                        >
                                            I must say it was an incredible experience. From the
                                            moment I contacted the agency to inquire about the trip to
                                            the moment I returned home, everything was handled with
                                            professionalism and efficiency. Thanks to the seamless
                                            arrangements made by Trawelmart. I highly recommend to
                                            planning their next adventure.
                                        </p>
                                    </div>
                                    <div
                                        className="xl:py-4 lg:py-3 md:py-3 py-2 xl:mt-3 mt-2 w-full"
                                        style={{ background: "#c4daf8" }}
                                    >
                                        <p className="flex justify-center">
                                            {stars.map((_, index) => (
                                                <FaStar key={index} className="text-yellow-500 mx-1" />
                                            ))}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* part three */}

                <section className="overflow-hidden xl:block lg:block md:block hidden container mb-8 xl:py-0 lg:py-0 md:py-0 py-2 mx-auto pb-12">
                    <div className="xl:mt-0 lg:mt-0 md:mt-0 mt-2">
                        <div
                            className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 grid-cols-3 xl:gap-2 lg:gap-2 md:gap-2 gap-2 
                    xl:px-24 lg:px-2 md:px-8 px-2"
                        >
                            <div className="flex flex-col items-center">
                                {/* 1st column */}

                                <div
                                    className="heigh bg-white xl:pt-2 lg:pt-2 md:pt-2 pt-2 xl:h-550 lg:h-550  rounded-md mt-4 w-full flex flex-col items-center justify-between"
                                    style={{ background: "#eaf0f8" }}
                                >
                                    <img
                                        src={t6}
                                        alt="dubai1"
                                        className="mt-2 rounded-md xl:w-60 lg:w-60 md:w-44 xl:h-60 lg:h-60 md:h-44 mx-auto"
                                    />
                                    <div className="font-semibold xl:text-xl lg:text-sm md:text-sm text-sm text-center mt-4">
                                        <p
                                            className=" font-semibold mb-3"
                                            style={{ fontFamily: "Figtree" }}
                                        >
                                            Mr. Nanda Kumar & Mrs. Pushpaltha{" "}
                                        </p>
                                        <hr
                                            className="mx-4"
                                            style={{ border: "1px dashed #ccc" }}
                                        ></hr>
                                        <p
                                            style={{ fontFamily: "Figtree" }}
                                            className="xl:py-3 py-2 xl:text-base lg:text-base md:text-xs text-sm font-normal xl:px-6 lg:px-4 md:px-2 px-2 xl:pb-2 lg:pb-14 xl:mb-8"
                                        >
                                            It was incredibly well-managed thanks to the friendliness
                                            and flawless planning of The Trawelmart India and Dubai
                                            staff, who arranged every aspect of the trip with
                                            expertise! Without a question, our experience with
                                            Trawelmart is the best thus far, and it's just the
                                            beginning of a very long trip! Many thanks!{" "}
                                        </p>
                                    </div>
                                    <div
                                        className="xl:py-4 lg:py-3 md:py-3 py-2 mt-2 w-full"
                                        style={{ background: "#c4daf8" }}
                                    >
                                        <p className="flex justify-center">
                                            {stars.map((_, index) => (
                                                <FaStar key={index} className="text-yellow-500 mx-1" />
                                            ))}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            {/* 2nd column */}
                            <div className="flex flex-col items-center">
                                <div
                                    className="heigh bg-white xl:pt-2 lg:pt-2 md:pt-2 pt-2 xl:h-550 lg:h-550  rounded-md mt-4 w-full flex flex-col items-center justify-between"
                                    style={{ background: "#eaf0f8" }}
                                >
                                    <img
                                        src={t5}
                                        alt="dubai1"
                                        className="mt-2 rounded-md xl:w-60 lg:w-60 md:w-44 xl:h-60 lg:h-60 md:h-44 mx-auto"
                                    />
                                    <div className="font-semibold xl:text-xl lg:text-sm md:text-sm text-sm text-center mt-4">
                                        <p
                                            className=" font-semibold mb-3"
                                            style={{ fontFamily: "Figtree" }}
                                        >
                                            Mr. Gurudatt & Mrs.Veena{" "}
                                        </p>
                                        <hr
                                            className="mx-4"
                                            style={{ border: "1px dashed #ccc" }}
                                        ></hr>
                                        <p
                                            style={{ fontFamily: "Figtree" }}
                                            className="xl:py-3 py-2 xl:text-base lg:text-base md:text-xs text-sm font-normal xl:px-6 lg:px-4 md:px-2 px-2 xl:pb-2 lg:pb-14 xl:mb-8"
                                        >
                                            We thank Trawelmart for meticulously planning our Dubai
                                            trip itinerary and for perfectly organising the trip by
                                            taking care of all our requirements until the very end
                                            without any hassles. We were able to enjoy this trip to
                                            the fullest without worrying about travel details, other
                                            trivial things since we had their support.
                                        </p>
                                    </div>
                                    <div
                                        className="xl:py-4 lg:py-3 md:py-3 py-2 mt-2 w-full"
                                        style={{ background: "#c4daf8" }}
                                    >
                                        <p className="flex justify-center">
                                            {stars.map((_, index) => (
                                                <FaStar key={index} className="text-yellow-500 mx-1" />
                                            ))}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            {/* 3rd column */}
                            <div className="flex flex-col items-center">
                                <div
                                    className="heigh bg-white xl:pt-2 lg:pt-2 md:pt-2 pt-2 xl:h-550 lg:h-550 rounded-md mt-4 w-full flex flex-col items-center justify-between"
                                    style={{ background: "#eaf0f8" }}
                                >
                                    <img
                                        src={t9}
                                        alt="dubai1"
                                        className="mt-2 rounded-md xl:w-60 lg:w-60 md:w-44 xl:h-60 lg:h-60 md:h-44 mx-auto"
                                    />
                                    <div className="marg-top font-semibold xl:text-xl lg:text-sm md:text-sm text-sm text-center xl:mt-3 lg:mt-4 md:mt-1 mt-3">
                                        <p
                                            className="font-semibold xl:mb-4 lg:mb-3 md:mb-3 mb-4"
                                            style={{ fontFamily: "Figtree" }}
                                        >
                                            Mr. Sriram & Mrs. Ashwini
                                        </p>
                                        <hr
                                            className="mx-4"
                                            style={{ border: "1px dashed #ccc" }}
                                        ></hr>
                                        <p
                                            style={{ fontFamily: "Figtree" }}
                                            className="xl:py-3 lg:py-3 md:py-3 py-2 xl:text-base lg:text-base md:text-xs text-sm font-normal xl:px-2 lg:px-4 md:px-2 px-2 xl:pb-2 lg:pb-24 md:pb-10 pb-2 xl:mb-8 lg:mb-1 md:mb-0 mb-0"
                                        >
                                            This is the second time we are opting trawelmart as our
                                            travel partner. We had our vacation planned to Dubai, the
                                            team coordinated very well and we had hazel free travel
                                            experience. Good job from Mamatha and Kajal.{" "}
                                        </p>
                                    </div>
                                    <div
                                        className="xl:py-4 lg:py-3 md:py-3 py-2 xl:mt-14 mt-2 w-full"
                                        style={{ background: "#c4daf8" }}
                                    >
                                        <p className="flex justify-center">
                                            {stars.map((_, index) => (
                                                <FaStar key={index} className="text-yellow-500 mx-1" />
                                            ))}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* mobile view  */}

            <div className="bg-white pb-12">
                <section className="overflow-hidden xl:hidden lg:hidden md:hidden block container mx-auto">
                    <div className="xl:mt-4 lg:mt-4 md:mt-4 mt-2">
                        <div
                            className="grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-3 grid-cols-1 xl:gap-2 lg:gap-2 md:gap-2 gap-6 
                                       xl:px-24 lg:px-12 md:px-8 px-2"
                        >
                            <div className="flex flex-col items-center">
                                {/* 1st column */}

                                <div
                                    className=" xl:pt-2 lg:pt-2 md:pt-2 pt-2  rounded-md mt-4 w-full flex flex-col items-center justify-between"
                                    style={{ background: "#eaf0f8" }}
                                >
                                    <img
                                        src={testi11}
                                        alt="dubai1"
                                        className="mt-2 rounded-md xl:w-60 lg:w-60 md:w-44 xl:h-60 lg:h-60 md:h-44 mx-auto px-4 xl:px-0"
                                    />
                                    <div className="font-semibold xl:text-xl lg:text-sm md:text-sm text-sm text-center mt-4">
                                        <p
                                            className=" font-semibold mb-4"
                                            style={{ fontFamily: "Figtree" }}
                                        >
                                            Mr. Venkannachar Krishna Murthy Rotti{" "}
                                        </p>

                                        <p
                                            style={{
                                                fontFamily: "Figtree",
                                                borderTop: "1px dashed #ccc",
                                            }}
                                            className="xl:py-3 py-2 xl:text-base lg:text-base md:text-xs text-sm font-normal xl:px-6 mx-4 lg:px-4 md:px-2 px-2 xl:pb-2 lg:pb-14"
                                        >
                                            The warmth and seamless arrangement by The Trawelmart
                                            India and Dubai staff expertly planned every detail of the
                                            trip, and it was exceptionally well-managed! Our journey
                                            with Trawelmart is undoubtedly the best and also the start
                                            of many, many more to come! Much obliged!
                                        </p>
                                        <div
                                            className="xl:py-4 lg:py-3 md:py-3 py-2 mt-2"
                                            style={{ background: "#c4daf8" }}
                                        >
                                            <p className="flex justify-center">
                                                {stars.map((_, index) => (
                                                    <FaStar
                                                        key={index}
                                                        className="text-yellow-500 mx-1"
                                                    />
                                                ))}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* 2nd column */}
                            <div className="flex flex-col items-center">
                                <div
                                    className=" xl:pt-2 lg:pt-2 md:pt-2 pt-2  rounded-md mt-4 w-full flex flex-col items-center justify-between"
                                    style={{ background: "#eaf0f8" }}
                                >
                                    <img
                                        src={t2}
                                        alt="dubai1"
                                        className="mt-2 rounded-md xl:w-60 lg:w-60 md:w-44 xl:h-60 lg:h-60 md:h-44 mx-auto px-4"
                                    />
                                    <div className="font-semibold xl:text-xl lg:text-sm md:text-sm text-sm text-center mt-4">
                                        <p
                                            className=" font-semibold mb-4"
                                            style={{ fontFamily: "Figtree" }}
                                        >
                                            Mr. Vishwanath & Ms. Bhagya{" "}
                                        </p>
                                        <p
                                            style={{
                                                fontFamily: "Figtree",
                                                borderTop: "1px dashed #ccc",
                                            }}
                                            className="xl:py-3 py-2 xl:text-base lg:text-base md:text-xs text-sm font-normal xl:px-6 mx-4 lg:px-4 md:px-2 px-2 xl:pb-2 lg:pb-14"
                                        >
                                            I can summarise my recent vacation to Dubai with Travel
                                            Mart in one word: AMAZING! It was my first time booking a
                                            package vacation. I was quite happy with my choice. The
                                            group's timing was impeccable throughout the trip, the
                                            planning was excellent. With special gratitude to
                                            Trawelmart.
                                        </p>
                                    </div>
                                    <div
                                        className="xl:py-4 lg:py-3 md:py-3 py-2 mt-2 w-full"
                                        style={{ background: "#c4daf8" }}
                                    >
                                        <p className="flex justify-center">
                                            {stars.map((_, index) => (
                                                <FaStar key={index} className="text-yellow-500 mx-1" />
                                            ))}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            {/* 3rd column */}
                            <div className="flex flex-col items-center">
                                <div
                                    className="xl:pt-2 lg:pt-2 md:pt-2 pt-2  rounded-md mt-4 w-full flex flex-col items-center justify-between"
                                    style={{ background: "#eaf0f8" }}
                                >
                                    <img
                                        src={t7}
                                        alt="dubai1"
                                        className="mt-2 rounded-md xl:w-60 lg:w-60 md:w-44 xl:h-60 lg:h-60 md:h-44 mx-auto px-4"
                                    />
                                    <div className="font-semibold xl:text-xl lg:text-sm md:text-sm text-sm text-center xl:mt-4 lg:mt-0 md:mt-1 mt-4">
                                        <p
                                            className="font-semibold mb-4"
                                            style={{ fontFamily: "Figtree" }}
                                        >
                                            Ms. Pooja Brahmadev{" "}
                                        </p>
                                        <p
                                            style={{
                                                fontFamily: "Figtree",
                                                borderTop: "1px dashed #ccc",
                                            }}
                                            className="xl:py-3 py-2 xl:text-base lg:text-base md:text-xs text-sm font-normal xl:px-6 mx-4 lg:px-4 md:px-2 px-2 xl:pb-2 lg:pb-14"
                                        >
                                            Thank you to the entire Trawelmart team and the team in Dubai.
                                            I really enjoyed the services provided by your team and
                                            I'm looking forward to much more travelling with Trawelmart.
                                        </p>
                                    </div>
                                    <div
                                        className="xl:py-4 lg:py-3 md:py-3 py-2 mt-2 w-full"
                                        style={{ background: "#c4daf8" }}
                                    >
                                        <p className="flex justify-center">
                                            {stars.map((_, index) => (
                                                <FaStar key={index} className="text-yellow-500 mx-1" />
                                            ))}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* part two */}

                <section className="overflow-hidden xl:hidden lg:hidden md:hidden block container xl:py-0 lg:py-0 md:py-0 py-0 mx-auto">
                    <div className="xl:mt-0 lg:mt-0 md:mt-0 mt-6">
                        <div
                            className="grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-3 grid-cols-1 xl:gap-2 lg:gap-2 md:gap-2 gap-2 
                                        xl:px-24 lg:px-12 md:px-8 px-2"
                        >
                            <div className="flex flex-col items-center">
                                {/* 1st column */}

                                <div
                                    className="bg-white xl:pt-2 lg:pt-2 md:pt-2 pt-2  rounded-md mt-4 w-full flex flex-col items-center justify-between"
                                    style={{ background: "#eaf0f8" }}
                                >
                                    <img
                                        src={t3}
                                        alt="dubai1"
                                        className="mt-2 rounded-md xl:w-60 lg:w-60 md:w-44 xl:h-60 lg:h-60 md:h-44 mx-auto px-4"
                                    />
                                    <div className="font-semibold xl:text-xl lg:text-sm md:text-sm text-sm text-center mt-4">
                                        <p
                                            className=" font-semibold mb-4"
                                            style={{ fontFamily: "Figtree" }}
                                        >
                                            Mr. Abhilash & Ms. Indhumathy{" "}
                                        </p>
                                        <hr
                                            className="mx-4"
                                            style={{ border: "1px dashed #ccc" }}
                                        ></hr>
                                        <p
                                            style={{ fontFamily: "Figtree" }}
                                            className="xl:py-3 py-2 xl:text-base lg:text-base md:text-xs text-sm font-normal xl:px-6 lg:px-4 md:px-2 px-2 xl:pb-2 lg:pb-14"
                                        >
                                            It was an exhilarating trip to Dubai. We had wonderful
                                            experiences through out. Many thanks to Trawel Mart. We
                                            visited most of the tourist places in Dubai and Abu Dhabi.
                                            Kudos to operations team, coordinator and guides; they
                                            took care of us through out the trip. Highly recommend
                                            TrawelMart for holiday planning.
                                        </p>
                                    </div>
                                    <div
                                        className="xl:py-4 lg:py-3 md:py-3 py-2 mt-2 w-full"
                                        style={{ background: "#c4daf8" }}
                                    >
                                        <p className="flex justify-center">
                                            {stars.map((_, index) => (
                                                <FaStar key={index} className="text-yellow-500 mx-1" />
                                            ))}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            {/* 2nd column */}
                            <div className="flex flex-col items-center mt-4">
                                <div
                                    className="bg-white xl:pt-2 lg:pt-2 md:pt-2 pt-2  rounded-md mt-4 w-full flex flex-col items-center justify-between"
                                    style={{ background: "#eaf0f8" }}
                                >
                                    <img
                                        src={t4}
                                        alt="dubai1"
                                        className="mt-2 rounded-md xl:w-60 lg:w-60 md:w-44 xl:h-60 lg:h-60 md:h-44 mx-auto px-4"
                                    />
                                    <div className="font-semibold xl:text-xl lg:text-sm md:text-sm text-sm text-center mt-4">
                                        <p
                                            className=" font-semibold mb-4"
                                            style={{ fontFamily: "Figtree" }}
                                        >
                                            Mr. Jeevan & Ms. Harshitha{" "}
                                        </p>
                                        <hr
                                            className="mx-4"
                                            style={{ border: "1px dashed #ccc" }}
                                        ></hr>
                                        <p
                                            style={{ fontFamily: "Figtree" }}
                                            className="xl:py-3 py-2 xl:text-base lg:text-base md:text-xs text-sm font-normal xl:px-6 lg:px-4 md:px-2 px-2 xl:pb-2 lg:pb-14"
                                        >
                                            Our trip to Dubai with Trawelmart was soo amazing. Their
                                            attention to detail and personalized service made my trip
                                            unforgettable. From luxurious accommodations to seamless
                                            travel arrangements, every aspect was handled with
                                            professionalism. Looking forward to many more tours with
                                            Trawelmart.{" "}
                                        </p>
                                    </div>
                                    <div
                                        className="xl:py-4 lg:py-3 md:py-3 py-2 mt-2 w-full"
                                        style={{ background: "#c4daf8" }}
                                    >
                                        <p className="flex justify-center">
                                            {stars.map((_, index) => (
                                                <FaStar key={index} className="text-yellow-500 mx-1" />
                                            ))}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            {/* 3rd column */}
                            <div className="flex flex-col items-center mt-4">
                                <div
                                    className="bg-white xl:pt-2 lg:pt-2 md:pt-2 pt-2  rounded-md mt-4 w-full flex flex-col items-center justify-between"
                                    style={{ background: "#eaf0f8" }}
                                >
                                    <img
                                        src={t8}
                                        alt="dubai1"
                                        className="mt-2 rounded-md xl:w-60 lg:w-60 md:w-44 xl:h-60 lg:h-60 md:h-44 mx-auto px-4"
                                    />
                                    <div className="font-semibold xl:text-xl lg:text-sm md:text-sm text-sm text-center mt-4">
                                        <p
                                            className=" font-semibold mb-4"
                                            style={{ fontFamily: "Figtree" }}
                                        >
                                            Mr. Rohan & Family
                                        </p>
                                        <hr
                                            className="mx-4"
                                            style={{ border: "1px dashed #ccc" }}
                                        ></hr>
                                        <p
                                            style={{ fontFamily: "Figtree" }}
                                            className="mt-2 py-2 xl:text-base lg:text-base md:text-xs text-sm font-normal xl:px-6 lg:px-4 md:px-2 px-2 xl:pb-2 lg:pb-14"
                                        >
                                            I must say it was an incredible experience. From the
                                            moment I contacted the agency to inquire about the trip to
                                            the moment I returned home, everything was handled with
                                            professionalism and efficiency. Thanks to the seamless
                                            arrangements made by Trawelmart. I highly recommend to
                                            planning their next adventure.
                                        </p>
                                    </div>
                                    <div
                                        className="xl:py-4 lg:py-3 md:py-3 py-2 mt-2 w-full"
                                        style={{ background: "#c4daf8" }}
                                    >
                                        <p className="flex justify-center">
                                            {stars.map((_, index) => (
                                                <FaStar key={index} className="text-yellow-500 mx-1" />
                                            ))}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* part three */}

                <section className="overflow-hidden xl:hidden lg:hidden md:hidden block container mb-8 xl:py-0 lg:py-0 md:py-0 py-2 mx-auto ">
                    <div className="xl:mt-0 lg:mt-0 md:mt-0 mt-4">
                        <div
                            className="grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-3 grid-cols-1 xl:gap-2 lg:gap-2 md:gap-2 gap-2 
                                            xl:px-24 lg:px-12 md:px-8 px-2"
                        >
                            <div className="flex flex-col items-center">
                                {/* 1st column */}

                                <div
                                    className="bg-white xl:pt-2 lg:pt-2 md:pt-2 pt-2  rounded-md mt-4 w-full flex flex-col items-center justify-between"
                                    style={{ background: "#eaf0f8" }}
                                >
                                    <img
                                        src={t6}
                                        alt="dubai1"
                                        className="mt-2 rounded-md xl:w-60 lg:w-60 md:w-44 xl:h-60 lg:h-60 md:h-44 mx-auto px-4"
                                    />
                                    <div className="font-semibold xl:text-xl lg:text-sm md:text-sm text-sm text-center mt-4">
                                        <p
                                            className=" font-semibold mb-4"
                                            style={{ fontFamily: "Figtree" }}
                                        >
                                            Mr. Nanda Kumar & Mrs. Pushpaltha{" "}
                                        </p>
                                        <hr
                                            className="mx-4"
                                            style={{ border: "1px dashed #ccc" }}
                                        ></hr>
                                        <p
                                            style={{ fontFamily: "Figtree" }}
                                            className="xl:py-3 py-2 xl:text-base lg:text-base md:text-xs text-sm font-normal xl:px-6 lg:px-4 md:px-2 px-2 xl:pb-2 lg:pb-14"
                                        >
                                            It was incredibly well-managed thanks to the friendliness
                                            and flawless planning of The Trawelmart India and Dubai
                                            staff, who arranged every aspect of the trip with
                                            expertise! Without a question, our experience with
                                            Trawelmart is the best thus far, and it's just the
                                            beginning of a very long trip! Many thanks!{" "}
                                        </p>
                                    </div>
                                    <div
                                        className="xl:py-4 lg:py-3 md:py-3 py-2 mt-2 w-full"
                                        style={{ background: "#c4daf8" }}
                                    >
                                        <p className="flex justify-center">
                                            {stars.map((_, index) => (
                                                <FaStar key={index} className="text-yellow-500 mx-1" />
                                            ))}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            {/* 2nd column */}
                            <div className="flex flex-col items-center mt-4">
                                <div
                                    className="bg-white xl:pt-2 lg:pt-2 md:pt-2 pt-2  rounded-md mt-4 w-full flex flex-col items-center justify-between"
                                    style={{ background: "#eaf0f8" }}
                                >
                                    <img
                                        src={t5}
                                        alt="dubai1"
                                        className="mt-2 rounded-md xl:w-60 lg:w-60 md:w-44 xl:h-60 lg:h-60 md:h-44 mx-auto px-4"
                                    />
                                    <div className="font-semibold xl:text-xl lg:text-sm md:text-sm text-sm text-center mt-4">
                                        <p
                                            className=" font-semibold mb-3"
                                            style={{ fontFamily: "Figtree" }}
                                        >
                                            Mr. Gurudatt & Mrs.Veena{" "}
                                        </p>
                                        <hr
                                            className="mx-4"
                                            style={{ border: "1px dashed #ccc" }}
                                        ></hr>
                                        <p
                                            style={{ fontFamily: "Figtree" }}
                                            className="xl:py-3 py-2 xl:text-base lg:text-base md:text-xs text-sm font-normal xl:px-6 lg:px-4 md:px-2 px-2 xl:pb-2 lg:pb-14"
                                        >
                                            We thank Trawelmart for meticulously planning our Dubai
                                            trip itinerary and for perfectly organising the trip by
                                            taking care of all our requirements until the very end
                                            without any hassles. We were able to enjoy this trip to
                                            the fullest without worrying about travel details, other
                                            trivial things since we had their support.
                                        </p>
                                    </div>
                                    <div
                                        className="xl:py-4 lg:py-3 md:py-3 py-2 mt-2 w-full"
                                        style={{ background: "#c4daf8" }}
                                    >
                                        <p className="flex justify-center">
                                            {stars.map((_, index) => (
                                                <FaStar key={index} className="text-yellow-500 mx-1" />
                                            ))}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            {/* 3rd column */}
                            <div className="flex flex-col items-center mt-4">
                                <div
                                    className="bg-white xl:pt-2 lg:pt-2 md:pt-2 pt-2  rounded-md mt-4 w-full flex flex-col items-center justify-between"
                                    style={{ background: "#eaf0f8" }}
                                >
                                    <img
                                        src={t9}
                                        alt="dubai1"
                                        className="mt-2 rounded-md xl:w-60 lg:w-60 md:w-44 xl:h-60 lg:h-60 md:h-44 mx-auto px-4"
                                    />
                                    <div className="font-semibold xl:text-xl lg:text-sm md:text-sm text-sm text-center mt-4">
                                        <p
                                            className="mt-0 font-semibold mb-4"
                                            style={{ fontFamily: "Figtree" }}
                                        >
                                            Mr. Sriram & Mrs. Ashwini
                                        </p>
                                        <hr
                                            className="mx-4"
                                            style={{ border: "1px dashed #ccc" }}
                                        ></hr>
                                        <p
                                            style={{ fontFamily: "Figtree" }}
                                            className="xl:py-3 py-2 xl:text-base lg:text-base md:text-xs text-sm font-normal xl:px-6 lg:px-4 md:px-2 px-2 xl:pb-2 lg:pb-14"
                                        >
                                            This is the second time we are opting trawelmart as our
                                            travel partner. We had our vacation planned to Dubai, the
                                            team coordinated very well and we had hazel free travel
                                            experience. Good job from Mamatha and Kajal.{" "}
                                        </p>
                                    </div>
                                    <div
                                        className="xl:py-4 lg:py-3 md:py-3 py-2 mt-2 w-full"
                                        style={{ background: "#c4daf8" }}
                                    >
                                        <p className="flex justify-center">
                                            {stars.map((_, index) => (
                                                <FaStar key={index} className="text-yellow-500 mx-1" />
                                            ))}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* <section className='overflow-hidden xl:hiden lg:hidden md:hidden block container mx-auto' >
                <div className='xl:mt-4 lg:mt-4 md:mt-4 mt-2'>
                    <div className='grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-3 xl:gap-2 lg:gap-2 md:gap-2 gap-2 
                    xl:px-24 lg:px-12 md:px-8 px-4'>
                        <div className='flex flex-col items-center'>
                            {/* 1st column 
                            <div className='bg-white xl:pt-2 lg:pt-2 md:pt-2  rounded-md mt-4 w-full flex flex-col items-center justify-between' style={{height: '550px', background: '#eaf0f8'}}>
                                <img src={testi11} alt="dubai1" className=' rounded-md xl:w-60 lg:w-60 md:w-44 xl:h-60 lg:h-60 md:h-44 mx-auto' />
                                <div className='font-lora font-semibold xl:text-md lg:text-sm md:text-sm text-sm text-center mt-4' style={{ fontFamily: 'Lora, serif' }}>
                                    <p className='xl:pb-4 lg:pb-4 md:pb-2 pb-2 border-b border-blue-700'>
                                        Mr. Venkannachar Krishna Murthy Rotti </p>
                                    <p className='xl:py-4 lg:py-4 md:py-2 py-2 xl:text-sm lg:text-sm md:text-xs text-sm font-normal xl:px-6 lg:px-4 md:px-2 px-2 xl:pb-9 lg:pb-14 border-b border-blue-700'>
                                        The warmth and seamless arrangement by The Trawelmart India and Dubai staff expertly planned every detail of the trip, and it was exceptionally well-managed! Our journey with Trawelmart is undoubtedly the best and also the start of many, many more to come! Much obliged!
                                    </p>
                                </div>
                                <div className='xl:py-4 lg:py-3 md:py-3 py-2'>
                                    <p className='flex justify-center'>
                                        {stars.map((_, index) => (
                                            <FaStar key={index} className="text-yellow-500 mx-1" />
                                        ))}
                                    </p>
                                </div>
                            </div>
                        </div>

                        {/* 2nd column 
                        <div className='flex flex-col items-center'>
                            <div className='bg-white xl:pt-2 lg:pt-2 md:pt-2 rounded-md mt-4 w-full flex flex-col items-center justify-between' style={{height: '550px', background: '#eaf0f8'}}>
                                <img src={t2} alt="dubai1" className=' rounded-md xl:w-60 lg:w-60 md:w-44 xl:h-60 lg:h-60 md:h-44 mx-auto' />
                                <div className='font-lora font-semibold xl:text-md lg:text-sm md:text-sm text-sm text-center mt-4' style={{ fontFamily: 'Lora, serif' }}>
                                    <p className='xl:pb-4 lg:pb-4 md:pb-7 pb-2 border-b border-blue-700'>
                                        Mr. Vishwanath & Ms. Bhagya </p>
                                    <p className='xl:py-4 lg:py-4 md:py-2 py-2 xl:text-sm lg:text-sm md:text-xs text-sm font-normal xl:px-6 lg:px-4 md:px-2 px-2 border-b border-blue-700'>
                                        I can summarise my recent vacation to Dubai with Travel Mart in one word: AMAZING! It was my first time booking a package vacation. I have to admit that I was quite happy with my choice. The group's timing was impeccable throughout the trip, and the planning was excellent. With special gratitude to Trawelmart.
                                    </p>
                                </div>
                                <div className='xl:py-4 lg:py-3 md:py-3 py-2'>
                                    <p className='flex justify-center'>
                                        {stars.map((_, index) => (
                                            <FaStar key={index} className="text-yellow-500 mx-1" />
                                        ))}
                                    </p>
                                </div>
                            </div>
                        </div>

                        {/* 3rd column
                        <div className='flex flex-col items-center'>
                            <div className='bg-white xl:pt-2 lg:pt-2 md:pt-2  rounded-md mt-4 w-full flex flex-col items-center justify-between' style={{height: '550px', background: '#eaf0f8'}}>
                                <img src={t7} alt="dubai1" className=' rounded-md xl:w-60 lg:w-60 md:w-44 xl:h-60 lg:h-60 md:h-44 mx-auto' />
                                <div className='font-lora font-semibold xl:text-md lg:text-sm md:text-sm text-sm text-center mt-4' style={{ fontFamily: 'Lora, serif' }}>
                                    <p className='xl:pb-4 lg:pb-4 md:pb-7 pb-2 border-b border-blue-700'>
                                        Ms. Pooja Brahmadev</p>
                                    <p className='xl:py-3 lg:py-4 md:py-4 py-2 xl:text-sm lg:text-sm md:text-xs text-sm font-normal xl:px-6 lg:px-4 md:px-2 px-2 border-b xl:pb-20 lg:pb-24 md:pb-16 border-blue-700'>
                                        Thank you to the entire Trawelmart team and the team in Dubai. I really enjoyed the services provided by your team and I'm looking forward to much more travelling with Trawelmart.                                    </p>
                                </div>
                                <div className='xl:py-4 lg:py-3 md:py-3 py-2'>
                                    <p className='flex justify-center'>
                                        {stars.map((_, index) => (
                                            <FaStar key={index} className="text-yellow-500 mx-1" />
                                        ))}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* part two 

            <section className='overflow-hidden xl:hiden lg:hidden md:hidden block container xl:py-0 lg:py-0 md:py-0 py-0 mx-auto' >
                <div className='xl:mt-0 lg:mt-0 md:mt-0 mt-2'>
                    <div className='grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-3 xl:gap-2 lg:gap-2 md:gap-2 gap-2 
                    xl:px-24 lg:px-12 md:px-8 px-4'>
                        <div className='flex flex-col items-center'>
                            {/* 1st column
                            <div className='bg-white xl:pt-2 lg:pt-2 md:pt-2 rounded-md mt-4 w-full flex flex-col items-center'>
                                <img src={t3} alt="dubai1" className=' rounded-md xl:w-60 lg:w-60 md:w-44 xl:h-60 lg:h-60 md:h-44 mx-auto' />
                                <div className='font-lora font-semibold xl:text-md lg:text-sm md:text-sm text-sm text-center mt-4' style={{ fontFamily: 'Lora, serif' }}>
                                    <p className='xl:pb-4 lg:pb-4 md:pb-2 pb-2 border-b border-blue-700'>
                                        Mr. Abhilash & Ms. Indhumathy </p>
                                    <p className='xl:py-4 lg:py-4 md:py-2 py-2 xl:text-sm lg:text-sm md:text-xs text-sm font-normal xl:px-6 lg:px-4 md:px-2 px-2 xl:pb-9 lg:pb-12 md:pb-12 border-b border-blue-700'>
                                        It was an exhilarating trip to Dubai. We had wonderful experiences through out. Many thanks to Trawel Mart. We visited most of the tourist places in Dubai and Abu Dhabi. Kudos to operations team, coordinator and guides; they took care of us through out the trip. Highly recommend TrawelMart for holiday planning.
                                    </p>
                                </div>
                                <div className='xl:py-4 lg:py-3 md:py-3 py-2'>
                                    <p className='flex justify-center'>
                                        {stars.map((_, index) => (
                                            <FaStar key={index} className="text-yellow-500 mx-1" />
                                        ))}
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* 2nd column 
                        <div className='flex flex-col items-center'>
                            <div className='bg-white xl:pt-2 lg:pt-2 md:pt-2 rounded-md mt-4 w-full flex flex-col items-center'>
                                <img src={t4} alt="dubai1" className=' rounded-md xl:w-60 lg:w-60 md:w-44 xl:h-60 lg:h-60 md:h-44 mx-auto' />
                                <div className='font-lora font-semibold xl:text-md lg:text-sm md:text-sm text-sm text-center mt-4' style={{ fontFamily: 'Lora, serif' }}>
                                    <p className='xl:pb-4 lg:pb-4 md:pb-2 pb-2 border-b border-blue-700'>
                                        Mr. Jeevan & Ms. Harshitha </p>
                                    <p className='xl:py-4 lg:py-4 md:py-2 py-2 xl:text-sm lg:text-sm md:text-xs text-sm font-normal xl:px-6 lg:px-4 md:px-2 px-2 xl:pb-9 lg:pb-12 md:pb-12 border-b border-blue-700'>
                                        Our trip to Dubai with Trawelmart was soo amazing. Their attention to detail and personalized service made my trip unforgettable. From luxurious accommodations to seamless travel arrangements, every aspect was handled with professionalism and care. Looking forward to many more tours with Trawelmart.                                    </p>
                                </div>
                                <div className='xl:py-4 lg:py-3 md:py-3 py-2'>
                                    <p className='flex justify-center'>
                                        {stars.map((_, index) => (
                                            <FaStar key={index} className="text-yellow-500 mx-1" />
                                        ))}
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* 3rd column
                        <div className='flex flex-col items-center'>
                            <div className='bg-white xl:pt-2 lg:pt-2 md:pt-2 rounded-md mt-4 w-full flex flex-col items-center'>
                                <img src={t8} alt="dubai1" className=' rounded-md xl:w-60 lg:w-60 md:w-44 xl:h-60 lg:h-60 md:h-44 mx-auto' />
                                <div className='font-lora font-semibold xl:text-md lg:text-sm md:text-sm text-sm text-center mt-4' style={{ fontFamily: 'Lora, serif' }}>
                                    <p className='xl:pb-4 lg:pb-4 md:pb-2 pb-2 border-b border-blue-700'>
                                        Mr. Rohan & Family</p>
                                    <p className='xl:py-3 lg:py-4 md:py-2 py-2 xl:text-sm lg:text-sm md:text-xs text-sm font-normal xl:px-6 lg:px-4 md:px-2 px-2 border-b xl:pb-5 lg:pb-2 md:pb-4 border-blue-700'>
                                        I must say it was an incredible experience. From the moment I contacted the agency to inquire about the trip to the moment I returned home, everything was handled with professionalism and efficiency. Overall, my vacation in Dubai was unforgettable, thanks to the seamless arrangements made by Trawelmart. I highly recommend them to anyone planning their next adventure.
                                    </p>
                                </div>
                                <div className='xl:py-4 lg:py-3 md:py-3 py-2'>
                                    <p className='flex justify-center'>
                                        {stars.map((_, index) => (
                                            <FaStar key={index} className="text-yellow-500 mx-1" />
                                        ))}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* part three 

            <section className='overflow-hidden xl:hiden lg:hidden md:hidden block container mb-8 xl:py-0 lg:py-0 md:py-0 py-2 mx-auto ' >
                <div className='xl:mt-0 lg:mt-0 md:mt-0 mt-2'>
                    <div className='grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-3 xl:gap-2 lg:gap-2 md:gap-2 gap-2 
                    xl:px-24 lg:px-12 md:px-8 px-4'>
                        <div className='flex flex-col items-center'>
                            {/* 1st column 
                            <div className='bg-white xl:pt-2 lg:pt-2 md:pt-2   rounded-md mt-4 w-full flex flex-col items-center'>
                                <img src={t6} alt="dubai1" className=' rounded-md xl:w-60 lg:w-60 md:w-44 xl:h-60 lg:h-60 md:h-44 mx-auto' />
                                <div className='font-lora font-semibold xl:text-md lg:text-sm md:text-sm text-sm text-center mt-4' style={{ fontFamily: 'Lora, serif' }}>
                                    <p className='xl:pb-4 lg:pb-4 md:pb-2 pb-2 border-b border-blue-700'>
                                        Mr. Nanda Kumar & Mrs. Pushpaltha </p>
                                    <p className='xl:py-4 lg:py-4 md:py-2 py-2 xl:text-sm lg:text-sm md:text-xs text-sm font-normal xl:px-6 lg:px-4 md:px-2 px-2 xl:pb-4 lg:pb-12 md:pb-8 border-b border-blue-700'>
                                        It was incredibly well-managed thanks to the friendliness and flawless planning of The Trawelmart India and Dubai staff, who arranged every aspect of the trip with expertise! Without a question, our experience with Trawelmart is the best thus far, and it's just the beginning of a very long trip! Many thanks!                                    </p>
                                </div>
                                <div className='xl:py-4 lg:py-3 md:py-3 py-2'>
                                    <p className='flex justify-center'>
                                        {stars.map((_, index) => (
                                            <FaStar key={index} className="text-yellow-500 mx-1" />
                                        ))}
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* 2nd column 
                        <div className='flex flex-col items-center'>
                            <div className='bg-white xl:pt-2 lg:pt-2 md:pt-2   rounded-md mt-4 w-full flex flex-col items-center'>
                                <img src={t5} alt="dubai1" className=' rounded-md xl:w-60 lg:w-60 md:w-44 xl:h-60 lg:h-60 md:h-44 mx-auto' />
                                <div className='font-lora font-semibold xl:text-md lg:text-sm md:text-sm text-sm text-center mt-4' style={{ fontFamily: 'Lora, serif' }}>
                                    <p className='xl:pb-4 lg:pb-4 md:pb-7 pb-2 border-b border-blue-700'>
                                        Mr. Gurudatt & Mrs.Veena </p>
                                    <p className='xl:py-4 lg:py-4 md:py-2 py-2 xl:text-sm lg:text-sm md:text-xs text-sm font-normal xl:px-6 lg:px-4 md:px-2 px-2 lg:pb-12 md:pb-4 border-b border-blue-700'>
                                        We thank Trawelmart for meticulously planning our Dubai trip itinerary and for perfectly organising the trip by taking care of all our requirements until the very end without any hassles. We were able to enjoy this trip to the fullest without worrying about travel details and other trivial things since we had their support.
                                    </p>
                                </div>
                                <div className='xl:py-4 lg:py-3 md:py-3 py-2'>
                                    <p className='flex justify-center'>
                                        {stars.map((_, index) => (
                                            <FaStar key={index} className="text-yellow-500 mx-1" />
                                        ))}
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* 3rd column 
                        <div className='flex flex-col items-center'>
                            <div className='bg-white xl:pt-2 lg:pt-2 md:pt-2   rounded-md mt-4 w-full flex flex-col items-center'>
                                <img src={t9} alt="dubai1" className=' rounded-md xl:w-60 lg:w-60 md:w-44 xl:h-60 lg:h-60 md:h-44 mx-auto' />
                                <div className='font-lora font-semibold xl:text-md lg:text-sm md:text-sm text-sm text-center mt-4' style={{ fontFamily: 'Lora, serif' }}>
                                    <p className='xl:pb-4 lg:pb-4 md:pb-7 pb-2 border-b border-blue-700'>
                                        Mr. Sriram & Mrs. Ashwini
                                    </p>
                                    <p className='xl:py-2 lg:py-6 md:py-2 py-2 xl:text-sm lg:text-sm md:text-xs text-sm font-normal xl:px-6 lg:px-4 md:px-2 px-2 border-b xl:pb-16 lg:pb-20 md:pb-20 border-blue-700'>
                                        This is the second time we are opting trawelmart as our travel partner. We had our vacation planned to Dubai, the team coordinated very well and we had hazel free travel experience. Good job from Mamatha and Kajal.                                    </p>
                                </div>
                                <div className='xl:py-4 lg:py-3 md:py-3 py-2'>
                                    <p className='flex justify-center'>
                                        {stars.map((_, index) => (
                                            <FaStar key={index} className="text-yellow-500 mx-1" />
                                        ))}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <Footer />
        </div>
    );
};

export default Homepage;
