import React, {useState} from 'react'
import Navbar from './components/navbar';
import Homepage from './components/homepage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


// import Homepage from './components/Homepage';
// import Footer from './components/footer';

function App() {

  const [popup, setPopup] = useState(false);


  return (
    <div>
      <Navbar />
      <Homepage />
      <ToastContainer />
      
      {/* <Homepage /> */}
      {/* <Footer /> */}
    </div>
  );
}

export default App;
